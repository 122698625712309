import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GeoapiService } from 'src/app/services/geoapi.service';

@Component({
  selector: 'app-prensa',
  templateUrl: './prensa.component.html',
  styleUrls: ['./prensa.component.css']
})
export class PrensaComponent implements OnInit {
  windowWidth: number;
  language: string;


   lang = sessionStorage.getItem("lang")
  constructor(private geoApiService: GeoapiService,private translate: TranslateService) {
    this.windowWidth = window.innerWidth;

   }

  ngOnInit(): void {
    this.initializeLanguage();
  
}
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth; 
  }
  initializeLanguage() {
    this.lang = sessionStorage.getItem("lang");
    this.language = sessionStorage.getItem("lang");

    // if (!this.lang) {
    //   this.lang = navigator.language.split("-")[0];
    // }

    // this.translate.setDefaultLang('en');
    // this.translate.use(this.lang);


      this.geoApiService.getIPAddress().then(ipAddress => {
        this.geoApiService.getCountry(ipAddress).subscribe({
          next: (r: any) => {
            //if (ipAddress === '190.189.226.20' || ipAddress === '81.45.91.154') {
            if ( ipAddress === '81.45.91.154') {
              if(sessionStorage.getItem("lang")){
                this.lang = sessionStorage.getItem("lang");
                this.language = sessionStorage.getItem("lang");
              }else{
                this.lang='es';
                this.language='es';
              }
            }else{
              this.lang = r.message?.country?.iso_code.toLowerCase();
              this.language = r.message?.country?.iso_code.toLowerCase();
              this.translate.use(this.lang);

            }
            
          }
        });
      });
  }


}
