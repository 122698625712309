import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppointmentsService } from 'src/app/services/appointments.service';

@Component({
  selector: 'app-reprograr',
  templateUrl: './reprograr.component.html',
  styleUrls: ['./reprograr.component.css']
})
export class ReprograrComponent implements OnInit {
  public name_appointment:any
  public idCita: any;
  public idfecha: any;
  public id_reprogramacion: any;
  public datos: any;




  constructor(private appointment: AppointmentsService,private route: ActivatedRoute) {
    this.route.params.subscribe(params => {

      if(params['id']){
        this.idCita = params['id'];
        this.idfecha = params['idfecha'];
      }
    });
   }

  ngOnInit(): void {
    this.getCita();
  }


  getCita() {
    this.appointment.getCita(this.idCita).subscribe({
      next: (r: any) => {
        this.name_appointment = r.message;
        if(r.message.reprogramacion){
          this.confirmReprogramacion();
        }
        console.log(r.message);

      },
    });
  }

  confirmReprogramacion() {
    this.appointment.confirmReprogramacion(this.idCita, this.idfecha).subscribe({
      next: (r: any) => {
        this.datos = r.message;
        console.log(r.message);
      },
    });
  }
  

}
