import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService implements OnDestroy {
  private ngUnsubscribe = new Subject();

  constructor(private http: HttpClient) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public encuestaCita(id_encuesta: any, idCita: any, puntuaciones: any,comentario:any): Observable<any> {
    const datos = this.construirDatos(id_encuesta, idCita, puntuaciones,comentario);
    return this.http
      .post<any>(`/api/method/atr_appointments.api.createSurveyAnswers`, datos)
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  private construirDatos(id_encuesta: any, id_cita: any, puntuaciones: any, comentario:any): any {    
    return {
      "survey": id_encuesta,
      "appointment": id_cita,
      "comentario": comentario,
      "answers": puntuaciones,
    };
  }

  public getEncuesta(id_encuesta:any): Observable<any> {
    let datos = {
      "name": id_encuesta 
    }
    return this.http
    .post<any>(`/api/method/atr_appointments.api.getSurvey`, datos );
  }
  public getIdEConcesionario(consecionario_id:any): Observable<any> {
    let datos = {
      "name": consecionario_id 
    }
    return this.http
    .post<any>(`/api/method/atr_appointments.api.getPlace`, datos );
  }

  public getCita(name_appointment:any): Observable<any> {
    let datos = {
      "name": name_appointment 
    }
    return this.http
      .post<any>(`/api/method/atr_appointments.api.getAppointment`, datos );
  }


  public confirmCliente(id_cita:any,confirm:any): Observable<any> {
    let datos = {
      "name": id_cita,
      "confirmado": confirm
    }
    return this.http
      .post<any>(`/api/method/atr_appointments.api.confirmClientAppointment`, datos );
  }

  public confirmLead(email:any): Observable<any> {
    return this.http
      .get<any>(`/api/method/atr_appointments.api.createDealVentas?email=`+email);
  }
  
  public confirmReprogramacion(id_cita:any,id_reprogramacion:any): Observable<any> {
    let datos = {
      "name_appointment": id_cita,
      "name_reprogramacion": id_reprogramacion
  }
    return this.http
      .post<any>(`/api/method/atr_appointments.api.confirmRescheduleAppointment`, datos );
  }
  public confirmConsecionario(id_cita:any,confirm:any): Observable<any> {
    let datos = {
      "name": id_cita,
      "confirmado": confirm
    }
    return this.http
      .post<any>(`/api/method/atr_appointments.api.confirmConcesionarioAppointment`, datos );
  }
  public getCitas(): Observable<any> {
    return this.http
      .get<any>(`/api/method/atr_appointments.api.getAppointments`);
  }

  public hasSurvey(appointment:any): Observable<any> {
    let datos = {
      "appointment": appointment 
    }
    return this.http
    .post<any>(`/api/method/atr_appointments.api.hasSurvey`, datos );
  }
}
