import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppointmentsService } from 'src/app/services/appointments.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cuestionario',
  templateUrl: './cuestionario.component.html',
  styleUrls: ['./cuestionario.component.css']
})
export class CuestionarioComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  public idCita: any;
  public confirm: any;
  public id_encuesta: any;
  public datos: any;
  public puntuaciones: any = {};
  public comentario: any;
  public appointment: any;
  public concesionario: any;
  public citas: any;
  public pregunta:string
  public buttonClicked = false;
  public preguntasCompletadas = false;
  public citaConcurrida = false;
  public citaNoConcurrida = false;
  public encuestaOk = false;
  public encuestaInvalid = false;
  public tieneSurvey = false;
  public loading = false;

  constructor(private appointments: AppointmentsService,private route: ActivatedRoute) {
    this.route.params.subscribe(params => {

        if(params['id']){
          this.idCita = params['id']
          this.id_encuesta = params['idEn']
        }
      });
  }

  async ngOnInit(): Promise<void> {
    this.hasSurvey();
    this.getEncuesta();
    this.getCita();
    //this.validarPreguntasCompletadas();

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  seleccionarEstrellas(seccion: string, cantidad: number) {
    if (this.puntuaciones && seccion) {
      this.puntuaciones[seccion] = cantidad;
      //this.validarPreguntasCompletadas();
      this.mostrarAdvertencia();
    }
  }
  
  validarPreguntasCompletadas(): boolean {
    // Lógica para validar si todas las preguntas están completas
    // Por ejemplo, podrías verificar si todas las preguntas tienen estrellas seleccionadas
  
    // Supongamos que tienes un array de preguntas en tu componente
    const preguntas = ['Pregunta1', 'Pregunta2', 'Pregunta3', 'Pregunta4'];
  
    // Verifica si todas las preguntas tienen datos
    return this.puntuaciones.every(puntuacion => puntuacion > 0);
    
  }


  encuestaCita() {
    if (Object.values(this.puntuaciones).every((v) => v !== 0) === false) {
      this.encuestaInvalid = true;
      return;
    }
    this.encuestaInvalid = false;
    let answers:any[] = []
    Object.entries(this.puntuaciones).find(([key, value]) => {
      answers.push(
        {
          "question": key,
          "stars": value
        }
      )
    });
    this.appointments.encuestaCita(this.id_encuesta, this.idCita, answers, this.comentario)
      .subscribe({
        next: (response: any) => {
          this.datos = response.message;
          this.buttonClicked = true;
          this.encuestaOk = true;
        },
      });
  }
  
  getEncuesta() {
    this.appointments.getEncuesta(this.id_encuesta).subscribe({
      next: (response: any) => {
        this.appointment = response.message;
  
        // Verificar si 'questions' es un array y tiene al menos un elemento
        if (this.appointment.questions && this.appointment.questions.length > 0) {
          for (var val of this.appointment.questions) {
            this.puntuaciones[val.question] = 0
          }
        } else {
          console.log('El array "questions" está vacío o no está definido.');
        }
      },
      error: (error: any) => {
        console.error('Error al obtener la encuesta:', error);
      },
    });
  }
  
  


  getCita() {
    this.appointments.getCita(this.idCita).subscribe({
      next: (r: any) => {
        this.citas = r.message;
      },
    });
  }

  hasSurvey() {
    this.loading = true;
    this.appointments.hasSurvey(this.idCita).subscribe({
      next: (r: any) => {
        if(r.message) {
          this.tieneSurvey = true;
        }

        this.loading = false;
      },
    });
  }

  mostrarAdvertencia(): boolean {
    // Verifica si la puntuación de la pregunta específica ('PreguntaEspecifica') es igual a 0
    return this.puntuaciones['PreguntaEspecifica'] === 0;
  }
  



  confirmCliente() {
    this.appointments.confirmCliente(this.idCita, "Yes")
      .subscribe({
        next: (si: any) => {
          this.datos = si.message;
          this.citaConcurrida = true

        },
      });
  }
  
  gracias() {
    // Puedes agregar lógica adicional si es necesario
    this.appointments.confirmCliente(this.idCita, "No")
    .subscribe({
      next: (si: any) => {
        this.datos = si.message;
        this.citaNoConcurrida = true

      },
    });


  }
  
}
