import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { GeoapiService } from './services/geoapi.service';
declare let $: any;
declare const gtag: Function; // <------------Important: the declartion for gtag is required!

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
  location: any;
  routerSubscription: any;
  idioma: string;
  lang: string;


  constructor(private router: Router, private translate: TranslateService, private geoApiService: GeoapiService, private route: ActivatedRoute) {    
     /** START : Code to Track Page View using gtag.js */
     this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
         gtag('event', 'page_view', {
            page_path: event.urlAfterRedirects
         })
        })
        /** END : Code to Track Page View  using gtag.js */
    
        this.geoApiService.getIPAddress().then(ipAddress => {
          this.geoApiService.getCountry(ipAddress).subscribe({
            next: (r: any) => {
              let countryIsoCode = r.message?.country?.iso_code || '';
              countryIsoCode = countryIsoCode.split('-')[0].toLowerCase();
              
              console.log(countryIsoCode);
              this.lang = countryIsoCode;
              if (this.lang === 'es') {
                sessionStorage.setItem("lang", "es");
                this.translate.setDefaultLang('en');
                this.translate.use('es');
              } else {
                this.translate.setDefaultLang('en');
                this.translate.use(countryIsoCode);
                sessionStorage.setItem("lang", countryIsoCode);
                /*this.translate.setDefaultLang('en');
                this.translate.use("en");
                sessionStorage.setItem("lang", "en");*/
              }
              this.idioma = countryIsoCode;
            },
            error: (err: any) => {
              console.error('Error fetching country information:', err);
              // Manejar error aquí, posiblemente establecer un idioma por defecto
            }
          });
      }).catch(err => {
        console.error('Error fetching IP address:', err);
        // Manejar error aquí, posiblemente establecer un idioma por defecto
      });

  }

  ngOnInit(){
      this.recallJsFuntions();
      
      
  }

  recallJsFuntions() {
      this.router.events
      this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(event => {
          $.getScript('assets/js/custom.js');
          window.scrollTo(0, 0);
      });
  }

  /*getIPAddress(): Promise<string> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', 'https://api.ipify.org?format=json', true);
      xhr.onload = () => {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          resolve(response.ip);
        } else {
          reject('No se pudo obtener la dirección IP');
        }
      };
      xhr.onerror = () => {
        reject('Error de red');
      };
      xhr.send();
    });
  }*/
}


