<app-header2></app-header2>


<section id="miDiv" class="banner-slider mb-5"> 
    <div class="">
        <div class="banner-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <div class="about-text-wrapper">
                                <h2 class="text-custom-white title">{{'Historial de vehículo'|translate|uppercase|nl2pbr}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="gradient">

    </div>
    
</section>
<section class=" news-sec-desktop bg-light-theme d-md-block">
    <div class="container-fluid  backgroud-w p-relative shadow-top" style="background: #FFF;">
      <div class="container">
        <div class="row mb-3" style="display: flex; justify-content: center;">
          <div class="col-lg-10 col-md-10 col-sm-12">
            <h2 class="texto-chico">{{'Ingrese su matrícula y PIN.'|translate}}</h2>
            <p>{{'Si no posee su PIN, ingrese solo su matrícula para ver los datos básicos del vehículo y podrá solicitarlo.'|translate}}</p>
            
            <form [formGroup]="historialForm" (ngSubmit)="consultarVehiculo()">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Matrícula</mat-label>
                            <input formControlName="matricula" type="text" matInput name="matricula" placeholder="Matrícula">
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>PIN</mat-label>
                            <input formControlName="codigo" type="text" matInput name="codigo" placeholder="PIN">
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <button [disabled]="loading" type="submit" class="btn btn-success">{{'Confirm'|translate}}</button>
                    </div>
                </div>
            </form>
          </div>
        </div>
        <div *ngIf="loading" class="row mt-3 mb-5" style="display: flex; justify-content: center;">
            <div class="col-lg-10 col-md-10 col-sm-12 text-center">
                <span class="spinner-border spinner-border-sm"></span> 
            </div>
        </div>
        <div *ngIf="vehiculo" class="row mt-3 mb-5" style="display: flex; justify-content: center;">
            <div class="col-lg-10 col-md-10 col-sm-12">
                <hr>
                <div class="row mt-3">
                    <div class="col-md-12 col-sm-12 col-lg-12">
                        <h4>Vin code: {{vehiculo.name}}</h4>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <p>Serial: {{vehiculo.serial}}</p>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <p>Color: {{vehiculo.color}}</p>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <p>Motor: {{vehiculo.motor}}</p>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <p>Module 1: {{vehiculo.battery_module_1}}</p>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <p>Module 2: {{vehiculo.battery_module_2}}</p>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <p>VCU: {{vehiculo.vcu}}</p>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <p>VCU SW: {{vehiculo.vcu_sw}}</p>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <p>BMS: {{vehiculo.bms}}</p>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <p>BMS SW: {{vehiculo.bms_sw}}</p>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <p>Controller: {{vehiculo.controller}}</p>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <p>Controller SW: {{vehiculo.controller_sw}}</p>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <p>Display SW: {{vehiculo.display_sw}}</p>
                    </div>
                </div>
                <div *ngIf="!vehiculo.token_valido" class="row mt-3">
                    <div class="col-md-12 col-sm-12 col-lg-12 text-center">
                        <hr>
                        <p>Para visualizar el histórico de su vehículo debe solicitar su PIN que le llegará al correo registrado en su compra.</p>
                        <button (click)="solicitarPin()" [disabled]="loadingPin" class="btn btn-black">Solicitar PIN</button>
                        <br>
                        <div *ngIf="pinOk" class="mt-3 alert alert-success">
                            <p>Se ha enviado el PIN al email que registró en su compra.</p>
                        </div>
                    </div>
                </div>
                <div *ngIf="vehiculo.token_valido" class="row mt-3">
                    <div class="col-md-12 col-sm-12 col-lg-12">
                        <hr>
                        <h4>Histórico de garantías</h4>
                        <div *ngIf="vehiculo.garantias?.length == 0" class="alert alert-secondary text-center">
                            No posee garantías.
                        </div>
                        <div *ngIf="vehiculo.garantias?.length > 0">
                            <div  *ngFor="let garantia of vehiculo.garantias" class="historial">
                                <p><b>Garantía: {{garantia.name}}</b></p>
                                <p><b>Fecha:</b> {{garantia.creation | date: 'dd/MM/y'}}</p>
                                <p><b>Descripción:</b> {{garantia.comentarios}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </section>
<app-footer2></app-footer2>
