import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proximamente',
  templateUrl: './proximamente.component.html',
  styleUrls: ['./proximamente.component.css']
})
export class ProximamenteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
