<div class="modal fade" id="alquila-scooter">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="modal-dialog">
                    <div class="container">
                        <div><h3><strong>Alquila Nerva EXE&nbsp;</strong><strong>Sin entrada&nbsp;</strong>desde&nbsp;<strong>{{'178,45'|translate}} &euro; / mes con seguro a todo riesgo, mantenimiento y</strong>&nbsp;neum&aacute;ticos incluidos 6000 km/a&ntilde;o</h3></div>
                        <h6><div>+ info:&nbsp;<a href="https://rentall-partners.com/nerva" target="_blank" rel="noopener" data-saferedirecturl="https://www.google.com/url?q=https://rentall-partners.com/nerva&amp;source=gmail&amp;ust=1663762678656000&amp;usg=AOvVaw0_YPIPWk7lC9qDh3B_luqh">https://rentall-<wbr />partners.com/nerva</a></div></h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>