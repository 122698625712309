import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GeoapiService {
  headers = new HttpHeaders().set('Authorization', 'Basic MGU5YTQwMmViNDc2NGExOjU4M2VjZTRjNTMzMzI2Nw==');
  constructor(private http: HttpClient) { }

  public getCountry(ip_address: any) {
    
    let data = {
      "ip_address": ip_address
    }

    return this.http
      .get(`/api/method/nerva.web.getCountryByIP?ip_address=`+ip_address, {headers: this.headers});
  }

  public getIPAddress(): Promise<string> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', 'https://api.ipify.org?format=json', true);
      xhr.onload = () => {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          resolve(response.ip);
        } else {
          reject('No se pudo obtener la dirección IP');
        }
      };
      xhr.onerror = () => {
        reject('Error de red');
      };
      xhr.send();
    });
  }
}
