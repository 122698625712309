import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeoapiService } from 'src/app/services/geoapi.service';

@Component({
  selector: 'app-concesionario',
  templateUrl: './concesionario.component.html',
  styleUrls: ['./concesionario.component.css'],
  
})
export class ConcesionarioComponent implements OnInit {

  constructor(private geoApiService: GeoapiService, private router: Router) { 
    
  }

  ngOnInit(): void {
    //this.redireccion();
    let node = document.createElement('script');
    node.src = "/assets/js/concesionario.js";//Change to your js file
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
    
    node = document.createElement('script');
    node.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAYK1Rx8uRxHfYrJXXNvca3XVRboVSef_4&libraries=places,geometry&solution_channel=GMP_QB_locatorplus_v4_cABCDE";//Change to your js file
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);

  }

  redireccion(){
    window.location.href = '/solicitar-prueba';

  }
}
