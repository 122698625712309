import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VehiculoService {
  headers = new HttpHeaders().set('Authorization', 'Basic MGU5YTQwMmViNDc2NGExOjU4M2VjZTRjNTMzMzI2Nw==');
  
  constructor(private http: HttpClient) { }

  public buscarVehiculo(consulta:any): Observable<any> {
    return this.http
      .post(`/api/method/nerva.web.getRegistroVehiculo`,consulta , {headers: this.headers});
  }

  public solicitarPin(consulta:any): Observable<any> {
    return this.http
      .post(`/api/method/nerva.web.generateTokenHistorial`,consulta , {headers: this.headers});
  }
}
