import { Component, OnInit } from '@angular/core';
import { ConcesionariosService } from 'src/app/services/concesionarios.service';

@Component({
  selector: 'app-home-popup',
  templateUrl: './home-popup.component.html',
  styleUrls: ['./home-popup.component.css']
})
export class HomePopupComponent implements OnInit {


  language:string
  testimonials:any
  url = 'https://nerva.eco/solicitar-prueba'
  testimonial :any;

  constructor(public concesionariosService:ConcesionariosService) { }

  ngOnInit(): void {
    let lang = navigator.language.split("-");
    this.language = lang[0];
    /*if (this.language == 'fr'){
      this.url = 'https://testdrive.nerva.eco/index.php?service=6'
    }else if (this.language == 'it') {
      this.url = 'https://testdrive.nerva.eco/index.php?service=4'
    }*/
    this.concesionariosService.getHighlightsTestimonials().subscribe({
      next: (r: any) => {
        this.testimonials = r.message
        console.log(this.testimonials)
      }
  })
  }
  asignarLogo() {
    this.testimonial.logo = '/files/android-chrome-256x256.png';
  }


}
