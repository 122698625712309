import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Header2Component } from './components/layouts/header2/header2.component';
import { Footer2Component } from './components/layouts/footer2/footer2.component';

import { Homepage2Component } from './components/pages/homepage2/homepage2.component';
import { modeloExeComponent } from './components/pages/modeloExe/modeloExe.component';
import { bydComponent } from './components/pages/byd/byd.component';
import { economiaCircularComponent } from './components/pages/economiaCircular/economiaCircular.component';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Nl2pbrPipe } from './pipes/nl2pbr.pipe';
import { ContactoComponent } from './components/pages/contacto/contacto.component';
import { PruebaConduccionComponent } from './components/pages/prueba-conduccion/prueba-conduccion.component';
import { ProximamenteComponent } from './components/pages/proximamente/proximamente.component';
import { ReactiveFormsModule } from '@angular/forms';

import { ModosConduccionComponent } from './components/layouts/modos-conduccion/modos-conduccion.component';
import { FichaTecnicaComponent } from './components/layouts/ficha-tecnica/ficha-tecnica.component';
import { RentingBateriasComponent } from './components/layouts/renting-baterias/renting-baterias.component';
import { PrivacidadLegalComponent } from './components/layouts/privacidad-legal/privacidad-legal.component';
import { PrivacidadNervaAppComponent } from './components/pages/privacidad-nerva-app/privacidad-nerva-app.component';
import { ConcesionarioComponent } from './components/pages/concesionario/concesionario.component';
import { CompraScooterComponent } from './components/layouts/compra-scooter/compra-scooter.component';
import { AlquilaScooterComponent } from './components/layouts/alquila-scooter/alquila-scooter.component';
import { QuieroUnaNervaComponent } from './components/pages/quiero-una-nerva/quiero-una-nerva.component';
import { PruebaConduccionPopupComponent } from './components/layouts/prueba-conduccion-popup/prueba-conduccion-popup.component';
import { SolicitudPruebaComponent } from './components/pages/solicitud-prueba/solicitud-prueba.component';

import {FormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { SolicitudPrubaFormComponent } from './components/pages/solicitud-pruba-form/solicitud-pruba-form.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import { PruebaConduccionTerminosCondicionesComponent } from './components/layouts/prueba-conduccion-terminos-condiciones/prueba-conduccion-terminos-condiciones.component';
import { CuestionarioComponent } from './components/pages/cuestionario/cuestionario.component';
import { ReprograrComponent } from './components/pages/reprograr/reprograr.component';
import { ConfirmLeadComponent } from './components/pages/confirm-lead/confirm-lead.component';
import { HomePopupComponent } from './components/layouts/home-popup/home-popup.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PrensaComponent } from './components/pages/prensa/prensa.component';
import { PruebaConduccionSeccionComponent } from './components/pages/prueba-conduccion-seccion/prueba-conduccion-seccion.component';
import { CitasCambioBmsComponent } from './components/pages/citas-cambio-bms/citas-cambio-bms.component';
import {MatStepperModule} from '@angular/material/stepper';
import { HistoricoVehiculoComponent } from './components/pages/historico-vehiculo/historico-vehiculo.component';
import { TestDriveButtonComponent } from './components/layouts/test-drive-button/test-drive-button.component';



export class TraduccionLoader implements TranslateLoader {

  constructor(private http: HttpClient) {}

  public getTranslation(lang: String): Observable<any> {
    return this.http.get(`https://api.nerva.eco/method/traducciones?codigo=${lang}`).pipe(map(
      (data) => {
        let res = {}
        data['data'].forEach(function (value) {
          //console.log(value);
          res[value.texto] = value.texto_traducido;
        });
        //console.log(res);
        return res;
      }
    ));

  }
}

@NgModule({
  declarations: [
    AppComponent,   
    Header2Component,
    Footer2Component,
    Homepage2Component,
    modeloExeComponent,
    bydComponent,
    economiaCircularComponent,
    Nl2pbrPipe,
    ContactoComponent,
    PruebaConduccionComponent,
    ProximamenteComponent,
    ModosConduccionComponent,
    FichaTecnicaComponent,
    RentingBateriasComponent,
    PrivacidadLegalComponent,
    PrivacidadNervaAppComponent,
    ConcesionarioComponent,
    CompraScooterComponent,
    AlquilaScooterComponent,
    QuieroUnaNervaComponent,
    PruebaConduccionPopupComponent,
    SolicitudPruebaComponent,
    SolicitudPrubaFormComponent,
    PruebaConduccionTerminosCondicionesComponent,
    CuestionarioComponent,
    ReprograrComponent,
    ConfirmLeadComponent,
    HomePopupComponent,
    PrensaComponent,
    PruebaConduccionSeccionComponent,
    CitasCambioBmsComponent,
    HistoricoVehiculoComponent,
    TestDriveButtonComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule, 
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatIconModule,
    MatStepperModule,
    MatSlideToggleModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useClass: TraduccionLoader,
          deps: [HttpClient]
      }
  }),
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, `/api/method/traducciones?codigo=`, "");
}
