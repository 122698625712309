<div class="modal fade" id="prueba-conduccion-terminos-condiciones">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="modal-dialog">
                    <div class="container">
                        <textarea readonly class="textareas" name="comentarios" rows="10" cols="50">
                            Terminos & Condiciones

                            
Está en la página web nerva.eco propiedad de la empresa NERVA ECO S.L. 


Desde nuestra empresa asumimos el compromiso de garantizar la privacidad de nuestros usuarios en todo momento y de no recabar información innecesaria ya que consideramos que la confidencialidad y la seguridad son valores importantes. 


Esta que detallamos en adelante es nuestra Política de Privacidad, en ella podrá consultar como recabamos sus datos y le explicaremos las siguientes cuestiones en relación con sus datos personales: 


Quién es el responsable del tratamiento de sus datos.

Para qué finalidades recabamos los datos que le solicitamos.

Cuál es la legitimación para su tratamiento.

Durante cuánto tiempo los conservamos.

A qué destinatarios se comunican sus datos.

Cuáles son sus derechos.



¿Quién es el responsable del tratamiento de sus datos? 


NERVA ECO S.L.

B75035386

Calle Arboleda, 18 - OF. EX 254

28031 Madrid

ESPAÑA 



En adelante “NERVA”, “Nosotros” o el “Responsable del Tratamiento”.


Datos personales



Esta política de privacidad cubre todos los datos recopilados y utilizados por nosotros a través de la compra por parte de los clientes de productos fabricados por NERVA, así como de la descarga y uso de nuestro aplicativo (en adelante, la “Web” y “App”, indistintamente).


Por lo tanto, esta Política de Privacidad es de aplicación, a los compradores de los productos fabricados por NERVA y, por lo tanto, a los usuarios de la App y/o Web (en adelante, se denominará a todos ellos conjuntamente como, los “Usuarios”, “Usuarios app” o “Usted”).


Qué son los datos personales recopilados?


Datos Personales hace referencia a cualquier información o datos que pueda identificarlo, ya sea directa o indirectamente.


Usted debe ser mayor de 18 años o en su caso, disponer de capacidad jurídica suficiente para poder suministrar sus datos personales. 


Datos que los Usuarios nos proporcionan directamente:


A través de la compra de productos fabricados por NERVA, en concesionarios autorizados:


Datos de carácter identificativo:


Nombre y apellidos

Correo electrónico

Número de teléfono

Número de matrícula asignado al vehículo

Dirección postal

DNI



A través de la reserva/compra online de productos, en la web de NERVA:


Datos de carácter identificativo:


Nombre y apellidos

Correo electrónico

Número de teléfono

Número de matrícula asignado al vehículo.

Dirección postal

DNI

Datos de su tarjeta de crédito. A este respecto, para su seguridad, hemos confiado en el sistema de pago mediante tarjeta de crédito o débito a una pasarela de pago segura. Los datos bancarios introducidos son encriptados y transmitidos de forma segura a los servidores de la entidad bancaria y posteriormente, son verificados con el banco emisor para evitar posibles fraudes y abusos.


A través del envío de correos electrónicos:


Cualquier dato personal que nos faciliten para responder a sus consultas o dudas por correo electrónico.

A través del formulario de contacto o de prueba del vehículo de la web:


Datos de carácter identificativo:


Nombre y apellidos

Correo electrónico

DNI

NÚMERO DE TELÉFONO




A través del uso del vehículo y acceso a las funcionalidades de la App


Los titulares o propietarios de los vehículos, así como los Usuarios app, reconocen que sus datos de carácter identificativo y de geolocalización son necesarios para el disfrute del vehículo y de las funcionalidades que ofrece la App. En cualquier caso, los usuarios y sus correspondientes Usuarios app, aceptan el tratamiento de sus datos personales para los fines que se exponen en la cláusula cuarta.


A través del registro en la App como Usuario de la app: 


Datos de carácter identificativo:


Nombre y apellido

Correo electrónico

Contraseña

Datos de Geolocalización



Datos de Geolocalización:


Dirección IP


Usted garantiza que todos los datos sobre su identidad y legitimidad facilitados a NERVA a través de la compra o adquisición de productos fabricados por éste, así como los datos facilitados al utilizar la App son veraces, exactos y completos. Asimismo, se compromete a mantener actualizados sus datos. En el supuesto de que usted facilite cualquier dato falso, inexacto o incompleto o si NERVA considera que existen motivos fundados para dudar sobre la veracidad, exactitud e integridad de los mismos, NERVA podrá denegarle el acceso y uso presente o futuro de la App o de cualquiera de sus contenidos y/o servicios.


De igual forma, el Usuario debe custodiar la contraseña identificativa para hacer uso de los servicios dispuestos en la App, y se compromete a no ceder su uso a terceros.


Obligación de facilitarnos sus datos personales y consecuencias de no hacerlo.


Los datos personales solicitados son necesarios para gestionar sus solicitudes, para el uso y disfrute de los productos adquiridos y, para prestarle los servicios y funcionalidades de la App y/o Web, por lo que, si no nos los facilita, no podremos atenderle correctamente ni prestarle los servicios proporcionados a través de la App y/o Web.


En todo caso, nos reservamos el derecho de decidir sobre la incorporación o no de sus datos personales y demás información a nuestras bases de datos.


Información que usted nos proporciona indirectamente al crear una cuenta y al utilizar la App y/o Web:


Información de cómo usa nuestros servicios, las funciones que utiliza y las acciones que lleva a cabo.



Puede obtener más información al respecto, en nuestra Política de cookies 


¿Para qué utilizamos sus datos personales?


Los datos personales facilitados a través de la compra de productos de NERVA, así como de la descarga de la App y uso de la Web son necesarios para:


Facilitarle un medio para que pueda ponerse en contacto con nosotros y, poder informarle (por email) sobre nuestros productos y las opciones de compra del vehículo.

Permitir el correcto uso y disfrute de todas las funcionalidades del vehículo.

Vincular los datos personales del usuario al vehículo y la App de NERVA.

Permitir la activación y funcionamiento de la App.

Permitir la prestación por parte de NERVA de los servicios postventa o de otros servicios vinculados a los productos.

Para contactar con Usted en caso de incidencias en el proceso de producción del vehículo.

Identificarlo como usuario y poder darle acceso a las diferentes funcionalidades que están a su disposición como titular del vehículo y usuario registrado de la App.

Entregar el producto en establecimientos o tiendas de terceros (concesionarios y/o distribuidores), a solicitud del usuario.

Otras finalidades relativas al uso de la App y/o Web:


El envío de promociones y publicidad relacionada con los productos y servicios que puedan ser de su interés (envío de newsletter), previo consentimiento expreso de Usted.

Gestionar y hacer seguimiento de la relación contractual que se mantiene con los Usuarios de la App y/o Web.

Comunicarnos con usted, incluso por correo electrónico, en relación con nuevos servicios, actualizaciones o cualquier otro asunto relativo al funcionamiento de la App.

Mejorar, desarrollar y probar nuestra App.

Proteger los derechos, la propiedad y/o la seguridad de NERVA.



Por otro lado, para el correcto funcionamiento de la App y Web, utilizamos cookies técnicas y funcionales. 



Legitimación para el tratamiento de los datos personales


Permitir el correcto uso y disfrute de los productos: La base de la legitimación en este sentido, es su consentimiento expreso en el momento de la compra de los productos.


Del mismo modo, consideramos que tenemos un interés legítimo, dado que estos datos son necesarios para el correcto uso y disfrute de todas las funcionalidades de los productos, así como permitir la activación y funcionamiento de los mismos.


− Gestionar las reservas/compras de los productos ofrecidos en la web: La base legitimadora es la relación contractual y precontractual mantenida con Usted en el momento de la aceptación de los Términos y Condiciones de compra de los productos que ofrecemos.


− Gestionar el acceso como Usuario de la App: El tratamiento de sus datos es necesario para la ejecución de los términos que regulan el uso de la App. Nos está facilitando su consentimiento aceptando esta política de privacidad a través de los mecanismos de consentimiento establecidos al momento de realizar la compra.


Del mismo modo, consideramos que tenemos un interés legítimo para realizar las comprobaciones necesarias para detectar y prevenir posibles fraudes cuando accede en la App.


Gestionar su registro como Usuario de la App: El tratamiento de sus datos personales y de geolocalización son necesarios para notificar al usuario (titular del producto) sobre el uso y localización del vehículo en todo momento. La base de la legitimación en este sentido, es su consentimiento expreso en el momento del registro en la App como usuario invitado.


Compartir datos de geolocalización durante el uso del vehículo y funcionalidades de la App: La base de la legitimación en este sentido, es su consentimiento expreso y la ejecución de la relación contractual, dado que estos datos son necesarios para geolocalizar el vehículo a través de la tarjeta SIM que lleva incorporada, mostrarle su ubicación en tiempo real y registrar el histórico de rutas en la App.


Compartir datos de geolocalización durante el uso del “mapa y/o navegador” integrado en la App: La base de la legitimación en este caso, es su consentimiento expreso al gestionar y configurar los datos de ubicación en su móvil.


Envío de comunicaciones comerciales: La base legitimadora es el consentimiento expreso que nos presta (por ejemplo: cuando autoriza el envío de publicidad o de nuestra newsletter).


− Atención al usuario: Desde NERVA tenemos un interés legítimo para atender las solicitudes o consultas de los usuarios a través de los diversos medios de contacto existentes. Entendemos que el tratamiento de estos datos resulta también beneficioso para el usuario en tanto que nos permite poder atender adecuadamente y resolver las consultas planteadas.


Para la gestión de incidencias relacionadas con el uso de la App y/o Web, el tratamiento es necesario para la ejecución de la relación contractual mantenida con usted.


Cuando la consulta está relacionada con el ejercicio de los derechos sobre los que le informamos más abajo, o con reclamaciones relacionadas con nuestros servicios, lo que nos legitima para tratar los datos es el cumplimiento de obligaciones legales por nuestra parte.


                        </textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
