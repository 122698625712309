import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-byd',
  templateUrl: './byd.component.html',
  styleUrls: ['./byd.component.css']
})
export class bydComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  goDown() {
    //this.scroller.scrollToAnchor("targetGreen");
    document.getElementsByClassName("news-sec-desktop")[0].scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

}
