import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-economiaCircular',
  templateUrl: './economiaCircular.component.html',
  styleUrls: ['./economiaCircular.component.css']
})
export class economiaCircularComponent implements OnInit {

  constructor(private route: ActivatedRoute, private translate: TranslateService) { }
  idioma: string;

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.route.queryParams.subscribe((params)=> {
    //     console.log(params);
        
    //     if(params['lang']){
    //       sessionStorage.setItem("lang", params['lang']);
    //       this.idioma = params['lang'];
    //       this.translate.setDefaultLang('es');
    //       this.translate.use(params['lang']);
    //     } else if (sessionStorage.getItem("lang")) {
    //       let lang = sessionStorage.getItem("lang")
    //       let language = lang;
    //       this.idioma = lang;
    //       this.translate.setDefaultLang('es');
    //       this.translate.use(language);
    //     } else {
    //       let lang = navigator.language.split("-");
    //       let language = lang[0];
    //       this.idioma = lang[0];
    //       this.translate.setDefaultLang('es');
    //       this.translate.use(language);
    //       console.log(language)
    //     }

    //   });
    // }, 1)
  }
  
  goDown() {
    //this.scroller.scrollToAnchor("targetGreen");
    document.getElementsByClassName("news-sec-desktop")[0].scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
  
}
