<app-header2></app-header2>
<body>
    
    <div class="banner" >
        <h1 style="color:white">
            {{'Pruebas de Conducción'|translate}}   

        </h1>
        <app-test-drive-button></app-test-drive-button>
    </div>
    <div *ngIf="windowWidth > 767">

<div class="row" style="margin-left: 5em; width:100%">
    <div class="textocard bordenewsrow col-5 row" >
        <div class="col-3">
         <img class="img-thumbnail"  src="/assets/images/yt1.jpeg" alt=""> 
        </div>
        <div class="col-9">
            <a href="https://www.youtube.com/watch?v=3SyoE8MwPN0&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=1" target="_blank">
                <h4 style="color:white" >{{'Prueba de la moto eléctrica Nerva Exe con baterías LFP de BYD'|translate}}
                </h4>
            </a>
         
            <p style="color:white">Mar 22, 2024, Forococheselectricos</p>

        </div>
    </div>

    <div class="textocard bordenewsrow col-6 row" style=" width:100%">
        <div class="col-3">
         <img class="img-thumbnail" src="/assets/images/yt2.webp" alt=""> 
        </div>
        <div class="col-9">
            <a href="https://www.youtube.com/watch?v=FJeniCKB9Fs&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=2" target="_blank">
                <h4 style="color:white" >{{'Primer contacto - Nerva Exe'|translate}}
                </h4>
            </a>
            <p style="color:white">Feb 27, 2022, Moto125ccTV</p>

        </div>
    </div>
    </div>
<div class="row" style="margin-left: 5em; width:100%">
    <div class="textocard bordenewsrow col-5 row" >
        <div class="col-3">
         <img class="img-thumbnail"  src="/assets/images/yt3.webp" alt=""> 
        </div>
        <div class="col-9">
            <a href="https://www.youtube.com/watch?v=41q3ySz1M1Y&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=3" target="_blank">
                <h4 style="color:white" >{{'NERVA EXE | Prueba'|translate}}
                </h4>
            </a>
           
            <p style="color:white">Feb 28, 2022, Todocircuito.com</p>

        </div>
    </div>

    <div class="textocard bordenewsrow col-6 row" style=" width:100%">
        <div class="col-3">
         <img class="img-thumbnail"  src="/assets/images/yt4.webp" alt=""> 
        </div>
        <div class="col-9">
            <a href="https://www.youtube.com/watch?v=0z0SUEOepwY&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=4" target="_blank">
                <h4 style="color:white" >{{'NERVA EXE _ MOTOR CUBRE.TV _ ITALIA'|translate}}
                </h4>
            </a>
            <p style="color:white">Jul 14, 2023, Todocircuito.com</p>

        </div>
    </div>
    </div>
<div class="row" style="margin-left: 5em; width:100%">
    <div class="textocard bordenewsrow col-5 row" >
        <div class="col-3">
         <img class="img-thumbnail"  src="/assets/images/yt5.webp" alt=""> 
        </div>
        <div class="col-9">
            <a href="https://www.youtube.com/watch?v=M90pQBroNHg&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=5" target="_blank">
                <h4 style="color:white" >{{'NERVA EXE 2023 EL MAXISCOOTER ELECTRICO CON BATERIA BYD'|translate}}
                </h4>
            </a>
          
            <p style="color:white">Abr 1, 2023, Muévete sin humos!!!</p>

        </div>
    </div>

    <div class="textocard bordenewsrow col-6 row" style=" width:100%">
        <div class="col-3">
         <img class="img-thumbnail"  src="/assets/images/yt6.webp" alt=""> 
        </div>
        <div class="col-9">
            <a href="https://www.youtube.com/watch?v=FSRzjRTuRYE&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=6" target="_blank">
                <h4 style="color:white">{{'Probamos la Nerva Exe | Nerva / Test Drive / Quadis.es'|translate}}
                </h4>
            </a>
            
            <p style="color:white">Feb 27, 2022, Moto125ccTV</p>

        </div>
    </div>
    </div>
<div class="row" style="margin-left: 5em; width:100%">
    <div class="textocard bordenewsrow col-5 row" style=" width:100%">
        <div class="col-3">
         <img class="img-thumbnail"  src="/assets/images/yt7.webp" alt=""> 
        </div>
        <div class="col-9">
            <a href="https://www.youtube.com/watch?v=wICKuDe8iHM&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=7" target="_blank">
                <h4 style="color:white">{{'NERVA EXE V2 en la Feria del VEM2022'|translate}}
                </h4>
            </a>
            <p style="color:white">Sep 18, 2022, Muévete sin humos!!!</p>

        </div>
    </div>

    <div class="textocard bordenewsrow col-6 row" style=" width:100%">
        <div class="col-3">
         <img class="img-thumbnail"  src="/assets/images/yt8.webp" alt=""> 
        </div>
        <div class="col-9">
            <a href="https://www.youtube.com/watch?v=LkfAEXehnRk&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=8" target="_blank">
                <h4 style="color:white">{{'Nerva EXE - vuelve su nueva versión para hacerle pruebas a fondo!'|translate}}
                </h4>
            </a>
            <p style="color:white">Feb 28, 2023, Muévete sin humos!!!</p>

        </div>
    </div>
    </div>
<div class="row" style="margin-left: 5em; width:100%">
    <div class="textocard bordenewsrow col-5 row" style=" width:100%">
        <div class="col-3">
         <img class="img-thumbnail"  src="/assets/images/yt9.webp" alt=""> 
        </div>
        <div class="col-9">
            <a href="https://www.youtube.com/watch?v=dctkIf-hZAg&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=9" target="_blank">
                <h4 style="color:white">{{'Nerva EXE 2023 - vistazo por dentro!!'|translate}}
                </h4>
            </a>
            <p style="color:white">Sep 18, 2022, Muévete sin humos!!!</p>

        </div>
    </div>

    <div class="textocard bordenewsrow col-6 row" style=" width:100%">
        <div class="col-3">
         <img class="img-thumbnail"  src="/assets/images/yt10.webp" alt=""> 
        </div>
        <div class="col-9">
            <a href="https://www.youtube.com/watch?v=uwYCsAU4ggQ&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=10" target="_blank">
                <h4 style="color:white"> {{'Nerva Exe 2023 - este finde pruebo la Maxiscooter eléctrica más bonita'|translate}}
                </h4>
            </a>
            <p style="color:white">Feb 28, 2023, Muévete sin humos!!!</p>

        </div>
    </div>
    </div>
<div class="row" style="margin-left: 5em; width:100%">
    <div class="textocard bordenewsrow col-5 row" style=" width:100%">
        <div class="col-3">
         <img class="img-thumbnail"  src="/assets/images/yt11.webp" alt=""> 
        </div>
        <div class="col-9">
            <a href="https://www.youtube.com/watch?v=Cnh961HJ1ao&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=11" target="_blank">
                <h4 style="color:white"> {{'Presentación Nerva EXE / Mucho más que un scooter eléctrico /motos.net'|translate}}
                </h4>
            </a>
            <p style="color:white">Mar 4, 2022, motos.net
            </p>

        </div>
    </div>

    <div class="textocard bordenewsrow col-6 row" style=" width:100%">
        <div class="col-3">
         <img class="img-thumbnail" src="/assets/images/yt12.webp" alt=""> 
        </div>
        <div class="col-9">
            <a href="https://www.youtube.com/watch?v=zo3O3AEQ2Gs&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=12" target="_blank">
                <h4 style="color:white">{{'NERVA EXE . Moto electrica, el video mas completo en español'|translate}}
                </h4>
            </a>
            <p style="color:white">Mar 11, 2023, Tonyenlared
            </p>

        </div>
    </div>
    </div>
    </div>


    <!-- RESPONSIVE -->
    <div *ngIf="windowWidth <= 767">

<div class="row" style="margin-left: 1em; width:100%">
    <div class="textocard bordenewsrow col-12 row" >
        <div class="col-4">
         <img style="width: auto; height:5em" src="/assets/images/yt1.jpeg" alt=""> 
        </div>
        <div class="col-8">
            <a href="https://www.youtube.com/watch?v=3SyoE8MwPN0&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=1" target="_blank">
                <h4 style="color:white" >{{'Prueba de la moto eléctrica Nerva Exe con baterías LFP de BYD'|translate}}
                </h4>
            </a>
         
            <p style="color:white">Mar 22, 2024, Forococheselectricos</p>

        </div>
    </div>

    <div class="textocard bordenewsrow col-12 row" style=" width:100%">
        <div class="col-4">
         <img style="width: auto; height:5em" src="/assets/images/yt2.webp" alt=""> 
        </div>
        <div class="col-8">
            <a href="https://www.youtube.com/watch?v=FJeniCKB9Fs&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=2" target="_blank">
                <h4 style="color:white" >{{'Primer contacto - Nerva Exe'|translate}}
                </h4>
            </a>
            <p style="color:white">Feb 27, 2022, Moto125ccTV</p>

        </div>
    </div>
    </div>
<div class="row" style="margin-left: 1em; width:100%">
    <div class="textocard bordenewsrow col-12 row" >
        <div class="col-4">
         <img style="width: auto;height:5em" src="/assets/images/yt3.webp" alt=""> 
        </div>
        <div class="col-8">
            <a href="https://www.youtube.com/watch?v=41q3ySz1M1Y&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=3" target="_blank">
                <h4 style="color:white" >{{'NERVA EXE | Prueba'|translate}}
                </h4>
            </a>
           
            <p style="color:white">Feb 28, 2022, Todocircuito.com</p>

        </div>
    </div>

    <div class="textocard bordenewsrow col-12 row" style=" width:100%">
        <div class="col-4">
         <img style="width: auto;height:5em" src="/assets/images/yt4.webp" alt=""> 
        </div>
        <div class="col-8">
            <a href="https://www.youtube.com/watch?v=41q3ySz1M1Y&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=4" target="_blank">
                <h4 style="color:white" >{{'NERVA EXE _ MOTOR CUBRE.TV _ ITALIA'|translate}}
                </h4>
            </a>
            <p style="color:white">Jul 14, 2023, Todocircuito.com</p>

        </div>
    </div>
    </div>
<div class="row" style="margin-left: 1em; width:100%">
    <div class="textocard bordenewsrow col-12 row" >
        <div class="col-4">
         <img style="width: auto;height:5em" src="/assets/images/yt5.webp" alt=""> 
        </div>
        <div class="col-8">
            <a href="https://www.youtube.com/watch?v=41q3ySz1M1Y&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=5" target="_blank">
                <h4 style="color:white" >{{'NERVA EXE 2023 EL MAXISCOOTER ELECTRICO CON BATERIA BYD'|translate}}
                </h4>
            </a>
          
            <p style="color:white">Abr 1, 2023, Muévete sin humos!!!</p>

        </div>
    </div>

    <div class="textocard bordenewsrow col-12 row" style=" width:100%">
        <div class="col-4">
         <img style="width: auto;height:5em" src="/assets/images/yt6.webp" alt=""> 
        </div>
        <div class="col-8">
            <a href="https://www.youtube.com/watch?v=41q3ySz1M1Y&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=6" target="_blank">
                <h4 style="color:white">{{'Probamos la Nerva Exe | Nerva / Test Drive / Quadis.es'|translate}}
                </h4>
            </a>
            
            <p style="color:white">Feb 27, 2022, Moto125ccTV</p>

        </div>
    </div>
    </div>
<div class="row" style="margin-left: 1em; width:100%">
    <div class="textocard bordenewsrow col-12 row" style=" width:100%">
        <div class="col-4">
         <img style="width: auto;height:5em" src="/assets/images/yt7.webp" alt=""> 
        </div>
        <div class="col-8">
            <a href="https://www.youtube.com/watch?v=41q3ySz1M1Y&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=7" target="_blank">
                <h4 style="color:white">{{'NERVA EXE V2 en la Feria del VEM2022'|translate}}
                </h4>
            </a>
            <p style="color:white">Sep 18, 2022, Muévete sin humos!!!</p>

        </div>
    </div>

    <div class="textocard bordenewsrow col-12 row" style=" width:100%">
        <div class="col-4">
         <img style="width: auto;height:5em" src="/assets/images/yt8.webp" alt=""> 
        </div>
        <div class="col-8">
            <a href="https://www.youtube.com/watch?v=41q3ySz1M1Y&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=8" target="_blank">
                <h4 style="color:white">{{'Nerva EXE - vuelve su nueva versión para hacerle pruebas a fondo!'|translate}}
                </h4>
            </a>
            <p style="color:white">Feb 28, 2023, Muévete sin humos!!!</p>

        </div>
    </div>
    </div>
<div class="row" style="margin-left: 1em; width:100%">
    <div class="textocard bordenewsrow col-12 row" style=" width:100%">
        <div class="col-4">
         <img style="width: auto;height:5em" src="/assets/images/yt9.webp" alt=""> 
        </div>
        <div class="col-8">
            <a href="https://www.youtube.com/watch?v=41q3ySz1M1Y&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=9" target="_blank">
                <h4 style="color:white">{{'Nerva EXE 2023 - vistazo por dentro!!'|translate}}
                </h4>
            </a>
            <p style="color:white">Sep 18, 2022, Muévete sin humos!!!</p>

        </div>
    </div>

    <div class="textocard bordenewsrow col-12 row" style=" width:100%">
        <div class="col-4">
         <img style="width: auto;height:5em" src="/assets/images/yt10.webp" alt=""> 
        </div>
        <div class="col-8">
            <a href="https://www.youtube.com/watch?v=41q3ySz1M1Y&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=10" target="_blank">
                <h4 style="color:white"> {{'Nerva Exe 2023 - este finde pruebo la Maxiscooter eléctrica más bonita'|translate}}
                </h4>
            </a>
            <p style="color:white">Feb 28, 2023, Muévete sin humos!!!</p>

        </div>
    </div>
    </div>
<div class="row" style="margin-left: 1em; width:100%">
    <div class="textocard bordenewsrow col-12 row" style=" width:100%">
        <div class="col-4">
         <img style="width: auto;height:5em" src="/assets/images/yt11.webp" alt=""> 
        </div>
        <div class="col-8">
            <a href="https://www.youtube.com/watch?v=41q3ySz1M1Y&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=11" target="_blank">
                <h4 style="color:white"> {{'Presentación Nerva EXE / Mucho más que un scooter eléctrico /motos.net'|translate}}
                </h4>
            </a>
            <p style="color:white">Mar 4, 2022, motos.net
            </p>

        </div>
    </div>

    <div class="textocard bordenewsrow col-12 row" style=" width:100%">
        <div class="col-4">
         <img style="width:auto;height:5em" src="/assets/images/yt12.webp" alt=""> 
        </div>
        <div class="col-8">
            <a href="https://www.youtube.com/watch?v=41q3ySz1M1Y&list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef&index=12" target="_blank">
                <h4 style="color:white">{{'NERVA EXE . Moto electrica, el video mas completo en español'|translate}}
                </h4>
            </a>
            <p style="color:white">Mar 11, 2023, Tonyenlared
            </p>

        </div>
    </div>
    </div>
    </div>

    <div class="textocard  col-12 d-flex align-items-center justify-content-center" style="width:100%;">
        <a href="https://www.youtube.com/playlist?list=PL9hGAOo_Se6hGBmBuuaoa4XpWsAU0J0Ef" target="_blank">

        <button type="button" class="ocultart btn bordes-btm" style="font-size: 12px; background-color: #0362c8; color: white; padding: 1em; border-radius:20px" data-dismiss="modal">
            {{ 'Ver lista completa' | translate }}
                <img style="height: 2.5em; padding-left:10px" src="/assets/images/icons8-youtube.svg" alt="">
              
        </button> 
    </a>
    </div>
    




    


</body>




<app-footer2></app-footer2>
<app-modos-conduccion></app-modos-conduccion>
<app-compra-scooter></app-compra-scooter>
<app-alquila-scooter></app-alquila-scooter>
