<div *ngIf="!citaConcurrida && !citaNoConcurrida" style="background-color: rgb(235, 235, 235); height: 100vh;">

    <div *ngIf="!loading && !tieneSurvey" class="container ">
      <div class="card" style="padding: 5em; height: 100vh;">
        
        <div class="text-center mt-5">
            <div class="mb-5" style="margin-top: 3em;">
                <img src="/assets/images/a8512342-dca6-4325-d12e-0b6b984b5b41.png" height="150px" width="150px" alt="">
              </div>
          <h4 class="mt5 b-5">¿Has asistido a la cita pautada?</h4>
        </div>
        <div class="row flex justify-content-center mt-5">
          <div class="text-center col-6">
            <button class="btn btn-input" (click)="confirmCliente()" style="background-color: green; width:10em;">Si</button>
          </div>
          <div class="text-center col-6">
            <button class="btn btn-input" (click)="gracias()" style="background-color: red; width:10em">No</button>
          </div>
         
        </div>
      </div>
    </div>
    <div *ngIf="!loading &&  tieneSurvey" class="container ">
        <div class="card" style="padding: 5em; height: 100vh;">
        
            <div class="text-center mt-5">
                <div class="mb-5" style="margin-top: 3em;">
                    <img src="/assets/images/a8512342-dca6-4325-d12e-0b6b984b5b41.png" height="150px" width="150px" alt="">
                  </div>
            </div>
            <div class="row flex justify-content-center mt-5">
             <p style="font-size: 20px;">La encuesta para esta cita ya fue realizada previamente.</p>            
            </div>
          </div>
    </div>
    <div *ngIf="loading " class="container ">
        <div class="card" style="padding: 5em; height: 100vh;">
        
            <div class="text-center mt-5">
                <div class="mb-5" style="margin-top: 3em;">
                    <img src="/assets/images/a8512342-dca6-4325-d12e-0b6b984b5b41.png" height="150px" width="150px" alt="">
                  </div>
            </div>
            <div class="row flex justify-content-center mt-5">
                <span class="spinner-border spinner-border-sm"></span>      
            </div>
          </div>
    </div>
</div>
  
<div *ngIf="citaNoConcurrida" style="background-color: rgb(235, 235, 235);height: 100vh;">

    <div class="container">
        <div class="card" style="padding: 5em; height: 100vh">
                <div class="mb-5 text-center" style="margin-top: 3em;">
                <img src="/assets/images/a8512342-dca6-4325-d12e-0b6b984b5b41.png" height="150px" width="150px" alt="">
              </div>
<h4 class="mt-5">¡Gracias por su tiempo!</h4>

</div>
</div>
</div>
<div *ngIf="citaConcurrida && !encuestaOk" style="background-color: rgb(235, 235, 235);">



<div class="container">
    <div class="card" style="padding: 5em;">
        <div  class="mt-5 mb-5 d-flex justify-content-center">
            <img src="/assets/images/a8512342-dca6-4325-d12e-0b6b984b5b41.png" height="300px" width="300px" alt="">
        </div>
        
        <h4><u *ngIf="appointment && appointment.title"> 
            {{appointment.title}}</u> 
        </h4>
        <hr>
        <div class="" style="padding:3em">
            <p class="mt-3" style="font-size:22px;"
             *ngIf="appointment && appointment.description">
             {{appointment.description}}

             <br><br><br>

             <div *ngIf="appointment && appointment.questions && appointment.questions.length > 0">
                <div class="text-center" *ngFor="let preg of appointment.questions">
                  <b class="mt-5" style="font-size:18px;">{{ preg.question }}</b>
                    <br>
                  <div class="row mt-3 d-flex justify-content-center">
                    <div class="mr-3">
                        <input class="surveyRadio" type="radio" id="{{preg.name}}-1" name="{{preg.name}}" value="1">
                        <label (click)="seleccionarEstrellas(preg.question, 1)" for="{{preg.name}}-1" class="col-1.5 card-text btn-border d-flex justify-content-center surveyRadioLabel">
                            <img src="/assets/images/star_1828884.png" alt="" height="15px"/>
                        </label>
                    </div>
                    <div class="mr-3">
                        <input class="surveyRadio"  type="radio" id="{{preg.name}}-2" name="{{preg.name}}" value="1">
                        <label (click)="seleccionarEstrellas(preg.question, 2)" for="{{preg.name}}-2" class="col-1.5 card-text btn-border d-flex justify-content-center surveyRadioLabel">
                            <img src="/assets/images/star_1828884.png" alt="" height="15px"/>
                            <img src="/assets/images/star_1828884.png" alt="" height="15px"/>
                        </label>
                    </div>
                    <div class="mr-3">
                        <input class="surveyRadio"  type="radio" id="{{preg.name}}-3" name="{{preg.name}}" value="1">
                        <label (click)="seleccionarEstrellas(preg.question, 3)" for="{{preg.name}}-3" class="col-1.5 card-text btn-border d-flex justify-content-center surveyRadioLabel">
                            <img src="/assets/images/star_1828884.png" alt="" height="15px"/>
                            <img src="/assets/images/star_1828884.png" alt="" height="15px"/>
                            <img src="/assets/images/star_1828884.png" alt="" height="15px"/>
                        </label>
                    </div>
                    <div class="mr-3">
                        <input class="surveyRadio"  type="radio" id="{{preg.name}}-4" name="{{preg.name}}" value="1">
                        <label (click)="seleccionarEstrellas(preg.question, 4)" for="{{preg.name}}-4" class="col-1.5 card-text btn-border d-flex justify-content-center surveyRadioLabel">
                            <img src="/assets/images/star_1828884.png" alt="" height="15px"/>
                            <img src="/assets/images/star_1828884.png" alt="" height="15px"/>
                            <img src="/assets/images/star_1828884.png" alt="" height="15px"/>
                            <img src="/assets/images/star_1828884.png" alt="" height="15px"/>
                        </label>
                    </div>
                    <div class="mr-3">
                        <input class="surveyRadio" type="radio" id="{{preg.name}}-5" name="{{preg.name}}" value="1">
                        <label (click)="seleccionarEstrellas(preg.question, 5)" for="{{preg.name}}-5" class="col-1.5 card-text btn-border d-flex justify-content-center surveyRadioLabel">
                            <img src="/assets/images/star_1828884.png" alt="" height="15px"/>
                            <img src="/assets/images/star_1828884.png" alt="" height="15px"/>
                            <img src="/assets/images/star_1828884.png" alt="" height="15px"/>
                            <img src="/assets/images/star_1828884.png" alt="" height="15px"/>
                            <img src="/assets/images/star_1828884.png" alt="" height="15px"/>
                        </label>
                    </div>
                  </div>
                  <br>
                <div class="mt-3 mb-3 row">
                    <div class="col-6">
                        <p>{{preg.texto_puntaje_bajo}}</p>
                    </div>
                    <div class="col-6">
                        <p>{{preg.texto_puntaje_alto}}</p>
                    </div>
                </div>
                  <br>
                </div>
                <b class="mb-3" style="font-size:18px;">Déjenos sus comentarios o preguntas por favor
                </b><!-- Ejemplo en el archivo HTML --><br/>
        
                
                <textarea [(ngModel)]="comentario" style="width: 100%;" name="descrtiption" id="" cols="30" rows="5"></textarea>
                <div *ngIf="this.encuestaInvalid" class="d-flex justify-content-center mt-5">
                    <div class="alert alert-danger">
                        <p>Debe responder todas las preguntas.</p>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-5">
                    <button class="btn btn-env" (click)="encuestaCita()">Enviar</button>
                </div>
            </div>
        </div>
        
    </div>
</div>

</div>

<div *ngIf="citaConcurrida && encuestaOk" style="background-color: rgb(235, 235, 235);height: 100vh;">

    <div class="container-card">
        <div class="card" style="padding: 5em; height: 100vh">
                <div class="mb-5 text-center" style="margin-top: 3em;">
                <img src="/assets/images/a8512342-dca6-4325-d12e-0b6b984b5b41.png" height="150px" width="150px" alt="">
              </div>
<h4 class="mt-5">¡Gracias por su tiempo!</h4>

</div>
</div>
</div>