import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactoService } from '../../../services/contacto.service';
import { ConcesionariosService } from '../../../services/concesionarios.service';
import { Router } from '@angular/router';
import { GeoapiService } from 'src/app/services/geoapi.service';

@Component({
  selector: 'app-prueba-conduccion',
  templateUrl: './prueba-conduccion.component.html',
  styleUrls: ['./prueba-conduccion.component.css']
})
export class PruebaConduccionComponent implements OnInit {

  public contactoForm: FormGroup;
  public loading: boolean = false;
  public exito: boolean = false;
  public submitted: boolean = false;
  public concesionarios: any [] = [];
  public provincias: any [] = [];
  public isLoading = true;




  constructor(private geoApiService: GeoapiService, private formBuilder: FormBuilder, private contactoService: ContactoService, private concesionariosService: ConcesionariosService, private router: Router) {
    let lang = navigator.language.split("-")[0];
    if (lang != "es") {
      this.router.navigate(['proximamente']);
    }
    
    this.geoApiService.getIPAddress().then(
      (ipAddress => {
          this.geoApiService.getCountry(ipAddress).subscribe({
              next: (r: any) => {
                  lang = r.message?.country?.iso_code.toLowerCase();
                  if (lang == "fr") {
                    this.router.navigate(['proximamente']);
                  }
              }
          })
      })
    )
  }

  ngOnInit(): void {
   
    
    this.createForm();
    this.getConcesionarios();
  }
  
  createForm() {
    this.contactoForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      apellidos: ['', Validators.required],
      dni: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telefono: ['', Validators.required],
      concesionario:['', Validators.required],
      city:['', Validators.required],
      cp:['', Validators.required],
      profesional: ['0'],
      provincia: ['']
    })
  }
  getProvincias(concesionarios) {

    var provincias = [];
    concesionarios.forEach( function(concesionario, indice, array) {
      
      provincias.push(concesionario.city);

    });
    
    provincias = provincias.filter(function(item, pos) {
      return provincias.indexOf(item) == pos;
    })
    
    this.provincias = provincias.sort();

    console.log(this.provincias)
  }

  filtrar() {
    this.f.concesionario.setValue("")
    this.isLoading = true
      this.concesionariosService.getConsesionarios(this.f.provincia.value).subscribe({
        next: (r: any) => {
         this.concesionarios = r
         this.isLoading = false
         if(this.concesionarios.length == 1){
          console.log(this.concesionarios[0].name)
          this.f.concesionario.setValue(this.concesionarios[0].name)
         }   
        }
      }) 
  } 
  
  getConcesionarios() {
    this.concesionariosService.getConsesionarios(null).subscribe({
      next: (r: any) => {
       this.concesionarios = r
       this.getProvincias(this.concesionarios)
       console.log(r)
       this.isLoading = false
      }
    })
  }
  
  get f() {
    return this.contactoForm.controls;
  }

  enviarConsulta() {
    this.submitted = true;
    if (this.contactoForm.invalid) {
      return;
    }
    
    this.loading = true;
    let description = 'Nombre completo: ' + this.f.nombre.value +' '+ this.f.apellidos.value +'<br/>' +
                      'DNI: ' + this.f.dni.value +'<br/>' +
                      'City: ' + this.f.city.value + '<br/>' +
                      'Postal Code: ' + this.f.cp.value + '<br/>' +
                      'Email: ' + this.f.email.value + '<br/>' +
                      'Phone: ' + this.f.telefono.value + '<br/>' +
                      'Concesionario: ' + this.f.concesionario.value;

    
    const contactoArray = {
      lead_name: this.f.nombre.value +' '+ this.f.apellidos.value,
      email_id: this.f.email.value,
      dni: this.f.dni.value,
      concesionario_nerva: this.f.concesionario.value,  
      type: 'Test Drive',
      // notes: description,
      phone: this.f.telefono.value,
      profesional: this.f.profesional.value,
      city: this.f.city.value,
      pincode: this.f.cp.value,
      source: 'Web nerva.eco'
    };

    console.log(contactoArray);

    this.contactoService.crearLead(contactoArray).subscribe({
      next: (r) => {
        this.loading = false;
        this.exito = true;
      },
      error: (e) => console.log(e)
    });
  }

  agendarCita(){
  let urlAgenda = `https://testdrive.nerva.eco/?concesionario=${this.f.concesionario.value}&nombre=${this.f.nombre.value}&apellido=${this.f.apellidos.value}&ciudad=${this.f.city.value}&email=${this.f.email.value}&codigoPostal=${this.f.cp.value}&telefono=${this.f.telefono.value}`;
  window.location.href = urlAgenda;
  }

}
