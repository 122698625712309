<app-header2></app-header2>
<section id="miDiv" class="banner-slider mb-5"> 
    <div class="">
        <div class="banner-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <div class="about-text-wrapper">
                                <h2 class="text-custom-white title">{{'Confirmación'|translate|uppercase|nl2pbr}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="gradient">

    </div>
    
</section>
<section class=" news-sec-desktop bg-light-theme d-md-block mt-5" #scrollContainer>
    <div class="container-fluid  backgroud-w p-relative shadow-top" style="background: #FFF;">
        <div class="row ml-1 mb-5">
          <div class="col-md-12">
            <div  class="alert alert-success text-center">
                <p>{{'Gracias por tu interés. Nos pondremos en contacto contigo'|translate}}                        </p>
            </div>
          </div>
        </div>
    </div>
</section>
<app-footer2></app-footer2>