import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GeoapiService } from 'src/app/services/geoapi.service';

@Component({
  selector: 'app-quiero-una-nerva',
  templateUrl: './quiero-una-nerva.component.html',
  styleUrls: ['./quiero-una-nerva.component.css']
})
export class QuieroUnaNervaComponent implements OnInit {
  lang = navigator.language.split("-")[0];
  language: string;
  idioma: string;
  
  constructor(private router: Router,private geoApiService: GeoapiService,private translate: TranslateService) { }

  ngOnInit(): void {
    this.initializeLanguage();
    this.redirectToProximamente()

    // if (lang != "es") {
    //   this.router.navigate(['proximamente']);
    // }
    
  }
  redirectToProximamente() {
    if (this.language === 'en') {
      this.router.navigate(['proximamente']);
    }
  }
  initializeLanguage() {
    this.lang = sessionStorage.getItem("lang");
    this.language = sessionStorage.getItem("lang");

    // if (!this.lang) {
    //   this.lang = navigator.language.split("-")[0];
    // }

    // this.translate.setDefaultLang('en');
    // this.translate.use(this.lang);
    this.idioma = this.lang;

      this.geoApiService.getIPAddress().then(ipAddress => {
        this.geoApiService.getCountry(ipAddress).subscribe({
          next: (r: any) => {
            //if (ipAddress === '190.189.226.20' || ipAddress === '81.45.91.154') {
            if ( ipAddress === '81.45.91.154') {
              if(sessionStorage.getItem("lang")){
                this.lang = sessionStorage.getItem("lang");
                this.language = sessionStorage.getItem("lang");
              }else{
                this.lang='es';
                this.language='es';
                console.log(this.lang);

              }
            }else{
              this.lang = r.message?.country?.iso_code.toLowerCase();
              this.language = r.message?.country?.iso_code.toLowerCase();
              this.translate.use(this.lang);
              this.idioma = this.lang;
              
            }
            
          }
        });
      });
  }
  goDown() {
    //this.scroller.scrollToAnchor("targetGreen");
    document.getElementsByClassName("news-sec-desktop")[0].scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
  
}
