import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-prueba-conduccion-seccion',
  templateUrl: './prueba-conduccion-seccion.component.html',
  styleUrls: ['./prueba-conduccion-seccion.component.css']
})
export class PruebaConduccionSeccionComponent implements OnInit {

  loading:boolean = false;
  windowWidth: number;

  constructor() { 
    this.windowWidth = window.innerWidth;

  }

  ngOnInit(): void {
    this.loading = true;
    document.body.classList.add('no-modal-backdrop'); // Agregar clase al cuerpo para evitar fondo oscuro
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth; 
  }

}
