import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactoService } from '../../../services/contacto.service';
import { ConcesionariosService } from '../../../services/concesionarios.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})


export class ContactoComponent implements OnInit {
  
  public contactoForm: FormGroup;
  public loading: boolean = false;
  public exito: boolean = false;
  public submitted: boolean = false;
  public concesionarios: any [] = [];
  public provincias: any [] = [];
  public isLoading = true;
  public pais: string = "";

  constructor(private formBuilder: FormBuilder, private contactoService: ContactoService, private concesionariosService: ConcesionariosService, private translate: TranslateService) { }

  ngOnInit(): void {
    let node = document.createElement('script');
    node.src = "/assets/js/concesionario-contacto.js";//Change to your js file
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
    
    node = document.createElement('script');
    node.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAYK1Rx8uRxHfYrJXXNvca3XVRboVSef_4&libraries=places,geometry&solution_channel=GMP_QB_locatorplus_v4_cABCDE";//Change to your js file
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);

    this.createForm();
    this.getConcesionarios();
    console.log(sessionStorage.getItem("lang"));
  }

  getProvincias(concesionarios) {

    var provincias = [];
    concesionarios.forEach( function(concesionario, indice, array) {
      
      provincias.push(concesionario.city);

    });
    
    provincias = provincias.filter(function(item, pos) {
      return provincias.indexOf(item) == pos;
    })
    
    this.provincias = provincias.sort();
  }

  filtrar() {
    /*this.contactoForm.patchValue({
      provincia: this.f.provincia.value
    })*/
    this.isLoading = true;
    this.concesionariosService.getConsesionarios(this.f.provincia.value).subscribe({
      next: (r: any) => {
       this.concesionarios = r
       this.isLoading = false
      }
    })
  } 
  
  getConcesionarios() {
    this.pais = ""
    if (sessionStorage.getItem("lang") == "it") {
      this.pais = "Italy"
    } else if (sessionStorage.getItem("lang") == "fr") {
      this.pais = "France"
    } else if (sessionStorage.getItem("lang") == "es") {
      this.pais = "Spain"
    } else {
      this.pais = ""
    }

    let data = {
      "pais": this.pais,
      "city": ""
    }

    this.concesionariosService.getConsesionariosV2(data).subscribe({
      next: (r: any) => {
       this.concesionarios = r;
       this.getProvincias(this.concesionarios);
       this.isLoading = false;
      }
    })
  }


  createForm() {
    this.pais = ""
    var disabled = false;
    if (sessionStorage.getItem("lang") == "it") {
      this.pais = "Italy"
      disabled = true;
    } else if (sessionStorage.getItem("lang") == "fr") {
      this.pais = "France"
      disabled = true;
    } else if (sessionStorage.getItem("lang") == "es") {
      this.pais = "Spain"
      disabled = true;
    } else {
      this.pais = ""
      disabled = false;
    }
    this.contactoForm = this.formBuilder.group({
      motivo: ['', Validators.required],
      consulta: ['', Validators.required],
      nombre: ['', Validators.required],
      apellidos: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telefono: ['', Validators.required],
      pais: [{value: this.pais, disabled: disabled}, Validators.required],
      cp: ['', Validators.required],
      profesional: ['Usuario Final'],
      provincia: ['']
    })
  }

  get f() {
    return this.contactoForm.controls;
  }

  enviarConsulta() {
    this.submitted = true;
    if (this.contactoForm.invalid) {
      return;
    }
    
    this.loading = true;
    
    const consulta = {
      nombre: this.f.nombre.value,
      apellido: this.f.apellidos.value,
      perfil: this.f.profesional.value,
      email: this.f.email.value,
      telefono: this.f.telefono.value,
      pais: this.f.pais.value,
      codigo_postal: this.f.cp.value,
      motivo_de_consulta: this.f.motivo.value,
      consulta: this.f.consulta.value
    };

    this.contactoService.guardarConsulta(consulta).subscribe({
      next: (r) => {
        this.loading = false;
        this.exito = true;
      },
      error: (e) => {
        this.loading = false;
        console.log(e);
      }
    });
  }

}
