import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppointmentsService } from 'src/app/services/appointments.service';

@Component({
  selector: 'app-confirm-lead',
  templateUrl: './confirm-lead.component.html',
  styleUrls: ['./confirm-lead.component.css']
})
export class ConfirmLeadComponent implements OnInit {

  constructor(private route: ActivatedRoute, private appointService: AppointmentsService) {
    this.route.params.subscribe(params => {
      console.log(params);
      if(params['email']){
        this.appointService.confirmLead(params['email']).subscribe({
          next: (r: any) => {
            console.log("ok");
          }
        })
      }
    });
  }

  ngOnInit(): void {
    
  }

}
