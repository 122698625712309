<app-header2></app-header2>

<section class="banner-slider">
  <div class="">
      <div class="banner-wrapper">
          <div class="container">
              <div class="row">
                  <div class="col-12">
                      <div>
                          <div class="about-text-wrapper">
                              <h3 class="text-custom-white title">{{'CONCESIONARIOS'|translate}}</h3>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div style="position: relative; bottom: 10px; width: 100%; ">
      <div class="row pl-4 pr-4">
          <div class="col-md-5">
          </div>
          <div class="text-center col-md-2">
              <a class="btn-second orange-btn wow fadeInUp m-1" href="javascript:void(0)">↓</a>
          </div>
          <div class="col-md-5 ">
              <a class="btn-second orange-btn wow fadeInUp m-1 btn-fixed-2" href="#">...</a>
          </div>
      </div>
  </div>
</section>


<section class=" news-sec-desktop bg-light-theme d-md-block" >
  <div class="container-fluid  backgroud-w p-relative shadow-top" style="background: #FFF;">
      <div class="row ml-1">
        <div class="col-md-12">            
          <div id="map-container">
            <div id="locations-panel">
              <div id="locations-panel-list">
                <h1 class="search-title">
                  <img src="https://fonts.gstatic.com/s/i/googlematerialicons/place/v15/24px.svg"/>
                    {{'Encuentre su concesionario.'|translate}}

                </h1>
                <div class="search-input">
                  <p>{{'País' | translate }}:</p>
                  <select id="location-country">
                    <option value="0">{{'Filtrar por país' | translate }}...</option>
                    <option value="Spain">{{'España' | translate }}</option>
                    <option value="Italy">{{'Italia' | translate }}</option>
                    <option value="France">{{'Francia' | translate }}</option>
                  </select>
                  <input id="location-search-input" type="text" [placeholder]="'Busque su domicilio' | translate">

                  <div id="search-overlay-search" class="search-input-overlay search">
                    <button id="location-search-button">
                      <img class="icon" src="https://fonts.gstatic.com/s/i/googlematerialicons/search/v11/24px.svg" alt="Search"/>
                    </button>
                  </div>
                </div>
                <div class="section-name" id="location-results-section-name">
                  {{'Concesionarios.'|translate}}
                </div>
                <div class="results">
                  <ul id="location-results-list"></ul>
                </div>
              </div>
              <div id="locations-panel-details">
              </div>
            </div> 
            <div id="gmp-map">
            </div>        
          </div>
        </div>
      </div>
  </div>
</section>    

<app-footer2></app-footer2>
