<div class="modal fade" id="compra-scooter">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="modal-dialog">
                    <div class="container">
                       <h3><b>Compra la parte ciclo desde 3.180 €* y alquila las baterías de tu Nerva EXE</b></h3>
                       <div>*Desembolso Inicial 4.480 &euro; para la adquisici&oacute;n de la parte ciclo (la parte ciclo es todo el veh&iacute;culo menos las bater&iacute;as)</div>
<div>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;  - 1.100 &euro; Plan Moves III sin achatarramiento = 3.380 €</div>
<div>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;  - 1.300 &euro; Plan Moves III con achatarramiento = 3.180 €</div>
<div>+ info:<a href="https://www.idae.es/ayudas-y-financiacion/para-movilidad-y-vehiculos/programa-moves-iii" target="_blank" rel="noopener" data-saferedirecturl="https://www.google.com/url?q=https://www.idae.es/ayudas-y-financiacion/para-movilidad-y-vehiculos/programa-moves-iii&amp;source=gmail&amp;ust=1663762678656000&amp;usg=AOvVaw2vJoBMgCnccjLI6WkAgbmc">https://www.idae.es/<wbr />ayudas-y-financiacion/para-<wbr />movilidad-y-vehiculos/<wbr />programa-moves-iii</a></div><br>
<h3><b>Y alquila las baterías:</b></h3>
<div><strong>&nbsp;+ 39,90 &euro;&nbsp;</strong>/ mes durante 5 a&ntilde;os, pasado este periodo, si renuevas el renting, te ponemos unas bater&iacute;a nuevas.&nbsp;</div>
<div>+ info en tu concesionario:<a href="https://nerva.eco/concesionario" target="_blank" rel="noopener" data-saferedirecturl="https://www.google.com/url?q=https://nerva.eco/concesionario&amp;source=gmail&amp;ust=1663762678656000&amp;usg=AOvVaw1xmbgXhn_mPfhnII72puZQ">https://nerva.<wbr />eco/concesionario</a></div>
<div>Tus bater&iacute;as usadas se destinan a proyectos humanitarios:&nbsp;<a href="https://nerva.eco/economiaCircular" target="_blank" rel="noopener" data-saferedirecturl="https://www.google.com/url?q=https://nerva.eco/economiaCircular&amp;source=gmail&amp;ust=1663762678656000&amp;usg=AOvVaw1O_y4zblnjxfYkJ44Av30s">https://nerva.<wbr />eco/economiaCircular</a></div>
<p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>