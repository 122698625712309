<div *ngIf="name_appointment" style="background-color: rgb(235, 235, 235); height: 100vh;">
    <div class="container-card">
      <div class="card" style="padding: 5em; height: 100vh;">
        <div class="text-center mt-5">
          <div class="alert alert-success" role="alert" *ngIf="name_appointment.reprogramacion.length > 0">
            <div class="mb-5 text-center" style="margin-top: 3em;">
                <img src="/assets/images/a8512342-dca6-4325-d12e-0b6b984b5b41.png" height="150px" width="150px" alt="">
              </div>
            <h3>{{'Su cita ha sido reprogramada con éxito.'| translate}}</h3>
          </div>
          <div class="alert alert-danger" role="alert"  *ngIf="name_appointment.reprogramacion.length == 0">
            <div class="mb-5 text-center" style="margin-top: 3em;">
                <img src="/assets/images/a8512342-dca6-4325-d12e-0b6b984b5b41.png" height="150px" width="150px" alt="">
              </div>
            <h3>{{'Su cita ya fue reprogramada.'| translate}}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  