<app-header2></app-header2>
<!-- Banner sec -->

<section class="banner-slider">
    <div class="">
        <div class="banner-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <div class="about-text-wrapper">
                                <h2 class="text-custom-white title">{{'Solicitar cita para cambio de BMS'|translate|uppercase|nl2pbr}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="gradient">

    </div>
    <div style="position: relative; bottom: 10px; width: 100%; ">
        <div class="row pl-4 pr-4">
            <div class="col-md-5">
            </div>
            <div class="text-center col-md-2">
            </div>
            <div class="col-md-5 ">
                <a class="btn-second orange-btn wow fadeInUp m-1 btn-fixed-2" href="#">...</a>
            </div>
        </div>
    </div>
</section>


<section class=" news-sec-desktop bg-light-theme d-md-block">
    <div class="container-fluid  backgroud-w p-relative shadow-top" style="background: #FFF;">
        <div class="container">
            <div class="row mt-5 mb-5" *ngIf="enviado_ok">
                <div class="col">
                    <div class="alert alert-success text-center" role="alert">
                        <h3>{{'Se ha creado la cita correctamente.'|translate}}</h3>
                        <h4>{{'El concesionario se pondra en contacto con usted para poder coordinar una fecha y hora para el cambio de BMS.'|translate}}</h4>
                    </div>
                </div>
            </div>
            <div class="row ml-1 mt-5 mb-5" *ngIf="!enviado_ok">
                <div class="col">
                    <h3 class="text-custom-black ">{{'Completar los datos para realizar la operacion.'|translate|uppercase|nl2pbr}}</h3>
                    <mat-horizontal-stepper [linear]="isLinear" #stepper>
                        <mat-step [stepControl]="firstFormGroup">
                          <form [formGroup]="firstFormGroup">
                            <ng-template matStepLabel>{{'Paso 1'|translate}}</ng-template>
                            <div class="row mt-4" style="justify-content:space-around">
                                <div class="col-6">
                                    <label for="matricula">{{'Nº de Matricula:'|translate}}</label><br/>
                                    <input type="text" class="form-control" formControlName="matricula" id="matricula" style="width: calc(100% - 44px); float: left;" required>
                                </div>
                            </div>
                            <div class="row text-center mt-4">
                                <div class="col">
                                    <button class="btn btn-success" [disabled]="validando" (click)="buscarVinCode()">{{'Validar'|translate}}</button>
                                </div>
                            </div>
                          </form>
                        </mat-step>
                        <mat-step [stepControl]="secondFormGroup">
                          <form [formGroup]="secondFormGroup">
                            <ng-template matStepLabel>{{'Paso 2'|translate}}</ng-template>
                            <div class="row" *ngIf="validacion_ok && cambiarDireccion">
                                <div class="col-sm-12 col-md-4 mt-5 mb-5">
                                    <label for="pais">{{'Pais'|translate}}:</label><br/>
                                    <select formControlName="pais" (change)="cambiarDireccionFuncion($event)" required>
                                        <option value="ESPAÑA" >{{'España'|translate}}</option>
                                        <option value="ITALIA" >{{'Italia'|translate}}</option>
                                        <option value="FRANCE" >{{'France'|translate}}</option>
                                    </select>
                                </div>
                                <div class="col-sm-12 col-md-4 mt-5 mb-5">
                                    <label for="ciudad">{{'Ciudad'|translate}}:</label><br/>
                                    <select formControlName="ciudad" (change)="filtrarPlaces($event)" required>
                                        <option value="" *ngIf="ciudades.length==0" >{{'Filtrando'|translate}}...</option>
                                        <option value="" *ngIf="ciudades.length>1" >{{'Seleccione su ciudad'|translate}}</option>
                                        <option *ngFor="let ciudad of ciudades" value="{{ ciudad }}">{{ ciudad }}</option>
                                    </select>
                                </div>
                                <div class="col-sm-12 col-md-4 mt-5 mb-5">
                                    <label for="matricula">{{'Selecciona tu tienda NERVA más cercana'|translate}}:</label><br/>
                                    <select formControlName="lugar_de_la_cita" (change)="semanasDisponibles($event)" required>
                                        <option value="" *ngIf="concesionarios.length==0" >{{'Seleccione la ciudad'|translate}}</option>
                                        <option value="" *ngIf="concesionarios.length>1" >{{'Seleccione su concesionario'|translate}}</option>
                                        <option *ngFor="let concesionario of concesionarios" value="{{ concesionario.name }}">{{ concesionario.place_name }} | {{ concesionario.address }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row" *ngIf="validacion_ok && !cambiarDireccion">
                                <div class="col-sm-12 col-md-4 mt-5 mb-5">
                                    <label for="vin_code">{{'Nº de Bastidor'|translate}}:</label><br/>
                                    <input type="text" class="form-control" formControlName="vin_code" id="vin_code" style="width: calc(100% - 44px); float: left;" readonly>
                                </div>
                                <div class="col-sm-12 col-md-4 mt-5 mb-5">
                                    <label for="concesionario">{{'Concesionario'|translate}}:</label><br/>
                                    <input type="text" class="form-control" formControlName="concesionario" id="concesionario" style="width: calc(100% - 44px); float: left;" readonly>
                                </div>
                                <!-- <div class="col-sm-12 col-md-4 mt-5 mb-5">
                                    <label for="matricula">Selecciona tu provincia:</label><br/>
                                    <select (change)="filtrar()"  formControlName="provincia">
                                        <option  *ngIf="isLoading" >Cargando concesionarios ...</option>
                                        <option value="" *ngIf="!isLoading">Selecciona tu provincia</option>
                                        <option *ngFor="let provincia of provincias" value="{{ provincia }}" class="text-uppercase">{{ provincia }}</option>
                                    </select>
                                </div> -->
                                <div class="col-sm-12 col-md-4 mt-5 mb-5">
                                    <label for="matricula">{{'Selecciona tu tienda NERVA más cercana'|translate}}:</label><br/>
                                    <select formControlName="lugar_de_la_cita" (change)="semanasDisponibles($event)" required>
                                        <option value="" *ngIf="concesionarios.length>1" >{{'Selecciona tu tienda NERVA más cercana'|translate}}</option>
                                        <option *ngFor="let concesionario of concesionarios" value="{{ concesionario.name }}">{{ concesionario.place_name }} | {{ concesionario.city }} | {{ concesionario.address }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-6">
                                    <button class="btn btn-warning" (click)="cambiarDireccionFuncion()" [disabled]="cambiarDireccion">{{'Cambiar dirección'|translate}}</button>
                                </div>
                                <div class="col-6">
                                    <button class="btn btn-success"  matStepperNext>{{'Siguiente'|translate}}</button>
                                </div>
                            </div>
                          </form>
                        </mat-step>
                        <mat-step [stepControl]="threeFormGroup">
                            <form [formGroup]="threeFormGroup">
                              <ng-template matStepLabel>{{'Paso 3'|translate}}</ng-template>
                              <h4>{{'Seleccionar tres opciones para realizar la cita'|translate}}.</h4>
                              <div class="row">
                                <div class="col-sm-12 col-md-4 mt-5 mb-5">
                                    <label for="matricula">{{'Seleccione la primer semana'|translate}}:</label><br/>
                                    <select formControlName="semana" (change)="filtrarSegundaOpcion($event)" required>
                                        <option value="" >{{'Opcion'|translate}} 1</option>
                                        <option *ngFor="let opcion of opciones;let i = index" [value]="i">{{ opcion.desde | date: 'd/MM/y' }} - {{ opcion.hasta | date: 'd/MM/y' }}</option>
                                    </select>
                                  </div>
                                  <div class="col-sm-12 col-md-4 mt-5 mb-5">
                                    <label for="matricula">{{'Seleccione la segunda semana:'|translate}}</label><br/>
                                    <select formControlName="semana2" (change)="filtrarTerceraOpcion($event)" required [disabled]="!opciones2">
                                        <option value="" >{{'Opcion'|translate}} 2</option>
                                        <option *ngFor="let opcion of opciones2;let i = index" [value]="i">{{ opcion.desde | date: 'd/MM/y' }} - {{ opcion.hasta | date: 'd/MM/y' }}</option>
                                    </select>
                                  </div>
                                  <div class="col-sm-12 col-md-4 mt-5 mb-5">
                                    <label for="matricula">{{'Seleccione la tercer semana:'|translate}}</label><br/>
                                    <select formControlName="semana3" (change)="filtrarTerceraOpcion($event)" required [disabled]="!opciones3">
                                        <option value="" >{{'Opcion'|translate}} 3</option>
                                        <option *ngFor="let opcion of opciones3;let i = index" [value]="i">{{ opcion.desde | date: 'd/MM/y' }} - {{ opcion.hasta | date: 'd/MM/y' }}</option>
                                    </select>
                                  </div>
                              </div>
                              


                              <!-- <div class="row mt-4" style="justify-content:center;" >
                                <mat-radio-group formControlName="semana" aria-label="Selecciona una opción">
                                  <div class="option-container" *ngFor="let opcion of opciones;let i = index">
                                    <label>Semana {{ i + 1 }} :</label>
                                    <label>{{ opcion.desde | date: 'd/MM/y' }} - {{ opcion.hasta | date: 'd/MM/y' }}</label>
                                    <mat-radio-button [value]="i"></mat-radio-button>
                                  </div>
                                </mat-radio-group>
                              </div> -->
                            </form>
                            <div class="row mt-4">
                                <div class="col-6">
                                    <button class="btn btn-danger" mat-button matStepperPrevious>{{'Atras'|translate}}</button>
                                </div>
                                <div class="col-6">
                                    <button class="btn btn-success" mat-button [disabled]="enviando" (click)="enviarCita()">{{'Enviar'|translate}}</button>
                                </div>
                            </div>
                          </mat-step>
                      </mat-horizontal-stepper>
                </div>
            </div>
            <div class="row mt-5 mb-5"></div>
            <div class="row mt-5 mb-5"></div>
            <div class="row mt-5 mb-5"></div>
        </div>
    </div>
</section>

