import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer2',
  templateUrl: './footer2.component.html',
  styleUrls: ['./footer2.component.css']
})
export class Footer2Component implements OnInit {
  public mostrarTestDrive: boolean = false;

  constructor() { }

  ngOnInit(): void {
    if(sessionStorage.getItem("lang") == "es" || sessionStorage.getItem("lang") == "fr" || sessionStorage.getItem("lang") == "it"){
      this.mostrarTestDrive = true;
    }else {
      this.mostrarTestDrive = false;
    }
  }

}
