<app-header2></app-header2>
<!-- Banner sec -->

<section class="banner-slider">
    <div class="">
        <div class="banner-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <div class="about-text-wrapper">
                                <h2 class="text-custom-white title">{{'Solicitar prueba de conducción'|translate|uppercase|nl2pbr}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="gradient">

    </div>
    <div style="position: relative; bottom: 10px; width: 100%; ">
        <div class="row pl-4 pr-4">
            <div class="col-md-5">
            </div>
            <div class="text-center col-md-2">
                <a class="btn-second orange-btn wow fadeInUp m-1" href="javascript:void(0)">↓</a>
            </div>
            <div class="col-md-5 ">
                <a class="btn-second orange-btn wow fadeInUp m-1 btn-fixed-2" href="#">...</a>
            </div>
        </div>
    </div>
</section>

<section class=" news-sec-desktop bg-light-theme d-md-block">
    <div class="container-fluid  backgroud-w p-relative shadow-top" style="background: #FFF;">
        <div class="container">
            <div class="row ml-1">
                <div class="col-sm-12 col-md-4 mt-5 mb-5">
                    <h3 class="text-custom-black ">{{'Solicitud para la prueba de conducción'|translate|uppercase|nl2pbr}}</h3>
                    <div *ngIf="exito" class="alert alert-success">
                        <p>{{'Gracias por tu interés en Nerva Exe.  Clica en el botón inferior para reservar tu prueba de conducción en el Concesionario seleccionado.'|translate}}                        </p>
                    </div>
                    <div *ngIf="exito">
                        <button type="button" (click)="agendarCita()" class="btn btn-dark">Reserva la cita para tu prueba de conducción</button>
                    </div>
                    <div *ngIf="!exito" class="mt-5">
                        <p>{{'Rellena el siguiente formulario y contactaremos contigo lo antes posible'|translate }}</p>
                        <form [formGroup]="contactoForm" (ngSubmit)="enviarConsulta()">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'NOMBRE'|translate}}</label>
                                        <input type="text" [ngClass]="{'error' : submitted && f.nombre.errors?.required}" class="form-control" formControlName="nombre" [placeholder]="'Escribe tu nombre' | translate" required>
                                        
                                    </div>

                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'APELLIDOS'|translate}}</label>
                                        <input type="text" class="form-control" [ngClass]="{'error' : submitted && f.apellidos.errors?.required}" formControlName="apellidos" [placeholder]="'Escribe tus apellidos' | translate" required>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'CIUDAD'|translate}}</label>
                                        <input type="text" [ngClass]="{'error' : submitted && f.city.errors?.required}" class="form-control" formControlName="city" [placeholder]="'Escribe tu ciudad' | translate" required>
                                    </div>

                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'CÓDIGO POSTAL'|translate}}</label>
                                        <input type="text" class="form-control" [ngClass]="{'error' : submitted && f.cp.errors?.required}" formControlName="cp" [placeholder]="'Escribe tu código postal' | translate" required>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>{{'PERFIL'|translate}}</label>
                                        <select formControlName="profesional" >
                                            <option selected="selected" value="0">{{'Usuario Final'|translate}}</option>
                                            <option value="1">{{'Profesional / Concesionario'|translate}}</option>
                                        </select>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>{{'DNI'|translate}}</label>
                                        <input type="text" class="form-control" [ngClass]="{'error' : submitted && f.dni.errors?.required}" formControlName="dni"[placeholder]="'Escribe tu DNI (Ejemplo: 75854244X)' | translate"  required>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>{{'CORREO ELECTRÓNICO'|translate}}</label>
                                        <input type="email" [ngClass]="{'error' : submitted && f.email.errors?.email || submitted && f.email.errors?.required}" class="form-control" formControlName="email" [placeholder]="'Escribe tu correo electrónico' | translate" required>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <!--<div class="col-md-6">
                                    <div class="form-group">
                                        <label>NÚMERO DE TELÉFONO</label>
                                        <select >
                                            <option>Prefijo</option>
                                        </select>
                                    </div>
                                </div>-->
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>{{'NÚMERO DE TELÉFONO'|translate}}</label>
                                        <input type="text" class="form-control" [ngClass]="{'error' : submitted && f.telefono.errors?.required}" formControlName="telefono" [placeholder]="'Escribe tu teléfono' | translate" required>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>{{'Al seleccionar "SOLICITAR", acepto que se pongan en contacto conmigo para programar una cita con una NERVA modelo EXE. Enviando el formulario declaro haber leído y aceptado la información de protección de datos.'|translate}}</p>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
                <div class="col-sm-12 col-md-2 mt-5 mb-5"></div>
                <div class="col-sm-12 col-md-6 mt-5 mb-5" >
                    <div *ngIf="!exito" class="mt-5">
                        <h3 class="text-custom-black ">{{'Red de concesionarios Nerva'|translate|uppercase|nl2pbr}}</h3>
                        <label>{{'Encuentra tu punto de venta y servicio oficial'|translate}}</label>
                        <form [formGroup]="contactoForm" (ngSubmit)="enviarConsulta()">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group"><br>
                                        <label>{{'SELECCIONA TU CONCESIONARIO NERVA'|translate}}</label>
                                        <select (change)="filtrar()"  formControlName="provincia">
                                            <option  *ngIf="isLoading" >{{'Cargando concesionarios ...'|translate}}</option>
                                            <option value="" *ngIf="!isLoading">{{'Selecciona tu provincia'|translate}}</option>
                                            <option *ngFor="let provincia of provincias" value="{{ provincia }}" class="text-uppercase">{{ provincia }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <select formControlName="concesionario" [ngClass]="{'error' : submitted && f.concesionario.errors?.required}" required>
                                            <option value="" *ngIf="concesionarios.length>1" >{{'Selecciona tu tienda NERVA más cercana'|translate}}</option>
                                            <option *ngFor="let concesionario of concesionarios" value="{{ concesionario.address_title }}">{{ concesionario.city }} | {{ concesionario.address_title }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-default btn-black" [disabled]="loading"><span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {{'SOLICITAR'|translate}}</button>
                                    </div>

                                </div>
                            </div>
                        </form>
                        <div class="row mt-5">
                            <div class="col-md-12 block-concesionario" *ngIf="isLoading">
                                {{'Cargando concesionarios ...'|translate}}
                            </div>
                            <div *ngIf="f.provincia.value && !isLoading">
                                <div class="col-md-12 block-concesionario" style="margin: 5px;" *ngFor="let concesionario of concesionarios">
                                    <h4>{{ concesionario.address_title }} </h4>
                                    {{ concesionario.address_line1 }}. {{ concesionario.city }}. {{ concesionario.pincode }}. {{ concesionario.country }}<br/>
                                    {{ concesionario.address_line2 }}<br *ngIf="concesionario.address_line2"/>
                                    {{ concesionario.horario }}<br/> 
                                    <a  *ngIf="concesionario.web" target="_blank" style="color: #000; text-decoration: underline" href="{{ concesionario.web }}">{{ concesionario.web }}</a>
                                </div>
                        </div>
                            
                    </div>
                </div>
                </div>
            </div>
        </div>

    </div>

</section>
<app-footer2></app-footer2>
