<nav class="navbar navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#" style="
      display: flex;
      flex-direction: column;
      align-items: center;
  ">
        <img src="/assets/images/favicon-32x32.png" alt="" class="d-inline-block align-text-top" >
        <h5>NERVA</h5>
      </a>
    </div>
  </nav>

<div class="container">
    <div class="justify-content-lg-between row space-2-bottom space-3-top">
        <div class="col-md-4 mb-5 mb-md-0 order-md-2">
            <div class="pl-md-4">
                <h2>Textos legales</h2>
                <p>Política de privacidad Nerva App</p>
            </div>
        </div>
        <div class="col-md-8 mb-5 mb-md-0 order-md-2 space-2-top">
            <div>
            <div class="container">
                <div class="row">
                    <div class="align-center-mobile col-12 col-md-12 col-sm-12">
                        <br><br>
                        <p>En el caso de que el usuario facilite alguna información de carácter personal, los datos recogidos en este sitio web serán tratados de forma leal y lícita con sujeción en todo momento a los principios y derechos recogidos en el Reglamento (UE) 2016/679, de 27 de abril, General de Protección de Datos (conocido comúnmente como "RGPD") y demás normativa aplicable.</p>
                        <p>NERVA ECO S.L. mantendrá comunicaciones comerciales con sus clientes por correo electrónico, de tal manera que, en caso de que se produjera la revocación del consentimiento por parte del cliente, ello conllevará de manera automática a la cancelación y abandono en la condición de Cliente por parte de quien ejecuta tal revocación.</p>
                        <p>NERVA ECO S.L. no puede garantizar la absoluta invulnerabilidad de los sistemas, por tanto, no asume ninguna responsabilidad por los daños y perjuicios derivados de alteraciones que terceros puedan causar en los sistemas informáticos, documentos electrónicos o ficheros del usuario.</p>
                        <p>Si opta por abandonar nuestro sitio web a través de enlaces a sitios web no pertenecientes a nuestra entidad, NERVA ECO S.L. no se hará responsable de las políticas de privacidad de dichos sitios web ni de las cookies que éstos puedan almacenar en el ordenador del usuario.</p>
                        <p>La aplicación Nerva App , si es autorizada por el usuario, utiliza la ubicación en segundo plano para localizar tu vehículo y los vehículos de otros usuarios de Nerva App. Es importante activar siempre los servicios de ubicación en segundo plano para disfrutar de todas las funcionalidades de la aplicación. Los datos de ubicación de tu vehículo se conservan de forma anónima .</p>
                        <p>Nuestra política con respecto al correo electrónico se centra en remitir únicamente comunicaciones que usted haya solicitado recibir.</p>
                        <p>El Usuario Web garantiza que los datos personales facilitados a NERVA ECO S.L. son veraces y se hace responsable de comunicar cualquier modificación en los mismos. El Usuario Web será el único responsable de cualquier daño o perjuicio, directo o indirecto, que pudiera ocasionar a NERVA ECO S.L. o a cualquier tercero a causa de la cumplimentación de los formularios con datos falsos, inexactos, incompletos o no actualizados.</p>
                        <p>En cumplimiento de lo dispuesto en el artículo 13 del RGPD le proporcionamos a continuación la siguiente información en relación con el tratamiento que realizamos de sus datos personales:</p>
                        <h3>SOBRE SUS DATOS</h3>
                        <p><strong>Responsable:</strong> NERVA ECO S.L.</p>
                        <p><strong>Finalidades del tratamiento:</strong> Mantener comunicación con clientes, asociados, proveedores, empleados o informarle de novedades, ofertas comerciales relacionadas con nuestra actividad.</p>
                        <p><strong>Legitimación:</strong>Por ejecución de un contrato o en interés legítimo de NERVA ECO S.L.</p>
                        <p><strong>Procedencia de los datos:</strong> Los datos que tratamos son siempre facilitados por el interesado.</p>
                        <br>
                        <p><strong>Categoría de los datos:</strong> Información comercial y datos identificativos.</p>
                        <p><strong>Destinatarios de sus datos:</strong> Datos personales se transferirán a los siguientes destinatarios:</p>
                        <ol>
                            <li>1. Cualquier tercero que proporcione servicios de tratamiento de datos a NERVA ECO S.L. para el normal desarrollo de sus funciones.</li>
                            <li>2. A terceras empresas, como asesorías contables, fiscales, crédito y caución, etc... con fines administrativos.</li>
                            <li>3. A la Oficina de consumidores y usuarios en caso de existir alguna reclamación.</li>
                            <li>4. A aquellas empresas transportistas que puedan tener encargado el envío a los clientes de los pedidos correspondientes. Estos destinatarios pueden estar ubicados dentro del territorio español como en fuera del mismo, en función del producto y/o servicio adquirido.</li>
                            <li>5. En los supuestos legalmente establecidos, como es el caso de las Fuerzas y Cuerpos de Seguridad.</li>
                            <li>6. A las administraciones públicas a las que tengamos que facilitar información con motivo del cumplimiento de nuestras obligaciones legales.</li>
                        </ol>
                        <p><strong>Durante cuánto tiempo almacenaremos sus datos: </strong>  Hasta que nos indique lo contrario, dejen de ser de utilidad para el fin que lo recabamos o exista un imperativo legal que así lo exija.</p>
                        <p><strong>Transferencias internacionales:</strong> No realizamos transferencias internacionales.</p>
                        <p><strong>Decisiones automatizadas:</strong> No tomamos ninguna decisión de forma automatizada.</p>
                        <p><strong>Elaboración de perfiles:</strong> No elaboramos perfiles en base a sus datos personales.</p>
                        <p><strong>Correos comerciales:</strong> No enviamos correos comerciales no solicitados (SPAM) a empresas ni particulares obteniendo sus datos de forma fraudulenta.</p>
                        <h3>SOBRE SUS DERECHOS</h3>
                        <p>Como interesado, tiene derecho a ejercer los siguientes derechos:</p>
                        <ul>
                            <li><strong>Acceso:</strong><br>Tiene derecho a que le informemos de lo siguiente:
                                <ol>
                                    <li>1. Puede tener copia de los datos personales objeto del tratamiento.</li>
                                    <li>2. Los fines del tratamiento, las categorías de datos personales que se traten y las posibles comunicaciones de datos y sus destinatarios.</li>
                                    <li>3. De ser posible, el plazo de conservación de sus datos. De no serlo, el criterio para determinar ese plazo.</li>
                                    <li>4. Del derecho a solicitar la rectificación o supresión de sus datos, la limitación al tratamiento u oponerse al mismo.</li>
                                    <li>5. Del derecho a presentar una reclamación ante la Autoridad de Control.</li>
                                    <li>6. Si se produce una transmisión internacional de datos, recibir información de las garantías adecuadas.</li>
                                    <li>7. De la existencia de decisiones automatizadas (incluyendo perfiles), la lógica aplicada y la consecuencia de este tratamiento.</li>
                                </ol>
                            </li>
                            <li><strong>Rectificacion:</strong><br>	Tiene derecho, además de rectificar los datos inexactos, a que se completen los datos personales incompletos, inclusive mediante una declaración adicional.</li>
                            <li><strong>Supresión:</strong><br>También conocido como “Derecho al Olvido”. Con este derecho podrá solicitarnos:
                                <ol>
                                    <li>1. La supresión de los datos personales sin dilación indebida cuando concurra alguno de los supuestos contemplados, como el tratamiento ilícito de datos o la desaparición de la finalidad que motivó el tratamiento o recogida.</li>
                                    <li>2. No obstante existen algunas excepciones. Por ejemplo cuando prevalezca el derecho de información o de expresión.</li>
                                </ol>
                            </li>
                            <li><strong>Oposición:</strong><br>Mediante este derecho podrá oponerse al tratamiento de sus datos cuando :                               
                                <ol>
                                    <li>1. Por motivos relacionados con su situación personal, debe cesar el tratamiento de sus datos, salvo que se acredite un interés legítimo o sea necesario para el ejercicio o defensa de reclamaciones.</li>
                                    <li>2. El tratamiento tenga por objeto la mercadotecnia directa.</li>
                                </ol>
                            </li>
                            <li><strong>Limitación:</strong><br>Este derecho le permite:
                                <ol>
                                    <li>1. Solicitarnos que suspendamos el tratamiento de sus datos en los siguientes supuestos: <br>
                                    Que se impugne la exactitud de los datos mientras se verifica la misma por el responsable. <br>
                                    Si ha ejercitado su derecho de oposición, mientras verificamos que nuestros derechos prevalezcan sobre los suyos.
                                    </li>
                                    <li>2. Solicitarnos que conservemos sus datos cuando: <br>
                                        El tratamiento de datos sea ilícito y se oponga a la supresión de sus datos y solicite en su lugar la limitación de su uso. <br>
                                        Ya no necesitemos sus datos para los fines del tratamiento, pero Ud. los necesite para la formulación, ejercicio o defensa de reclamaciones.
                                    </li>
                                </ol>
                            </li>
                            <li><strong>Portabilidad:</strong><br>Este derecho le permite recibir sus datos en un formato estructurado de uso común y lectura mecánica y poder transmitirlo a otro responsable, siempre que sea técnicamente posible.</li>
                            <li><strong>No ser objeto de decisiones automatizadas:</strong><br>Tiene derecho a no ser objeto de decisiones basadas únicamente en el tratamiento automatizado, incluida la elaboración de perfiles, que produzca efectos jurídicos o le afecte. Se exceptúa lo anterior cuando:
                                <ol>
                                    <li>1. Sea necesario para la elaboración o ejecución de un contrato.</li>
                                    <li>2. Esté permitido por el derecho de la U.E. o de los estados miembros con medidas adecuadas para salvaguardar sus derechos y libertades.</li>
                                    <li>3. Tengamos su consentimiento.</li>
                                </ol>
                            </li>
                            <li><strong>Reclamar ante la autoridad de control:</strong><br>Si entiende que sus derechos no son debidamente respetados, puede dirigirse a la Agencia Española de Protección de Datos.</li>
                        </ul>
                        <p>Para poder ejercitar estos derechos, sólo tiene que enviarnos un email o mandarnos una carta, identificándose debidamente mediante copia de su DNI o carné de conducir. Deberá de especificar qué derecho o derechos desea ejercer y su motivación. Recibirá contestación con nuestra resolución en el plazo legalmente establecido.</p>
                        <p>Si usted no desea recibir nuestra información, póngase en contacto con nosotros enviando un correo electrónico a la siguiente dirección: marketing@nerva.es</p>
                        <p>Atentamente, <br>
                            NERVA ECO S.L.</p>
                            <br><br>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    