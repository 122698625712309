import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-drive-button',
  templateUrl: './test-drive-button.component.html',
  styleUrls: ['./test-drive-button.component.css']
})
export class TestDriveButtonComponent implements OnInit {
  public mostrarTestDrive: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
    console.log(sessionStorage.getItem("lang"));
    if(sessionStorage.getItem("lang") == "es" || sessionStorage.getItem("lang") == "fr" || sessionStorage.getItem("lang") == "it"){
      this.mostrarTestDrive = true;
    }else {
      this.mostrarTestDrive = false;
    }

  }

}
