
<div class="modal fade" id="modosconduccion">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="modal-dialog">
                    <span>{{'115 Km en conducción mixta, urbana e interurbana.'|translate}}</span>
                    <h3>{{'MODOS DE CONDUCCIÓN'|translate}}</h3>
                    <h5>{{'Nerva EXE cuenta con tres modos de conducción para que el conductor tenga distintas experiencias y pueda escoger la que mejor se adapte a cada momento.'|translate}}</h5><br>
                    <div class="row">
                        <div class="col">
                            <div class="container-modos">
                                <div class="container"><br>
                                    <h2>NORMAL</h2>
                                    <p>{{'En este modo, la velocidad máxima está limitada a 80 km/h y es el adecuado para un consumo moderado en carreteras secundarías o vías de circulación urbanas.'|translate}}</p>
                                </div>
                                <div class="container" style="text-align: center;">
                                    <span>{{'AUTONOMÍA APROX.*'|translate}}</span>
                                    <h3>115 km</h3>
                                    <span>{{'VELOCIDAD PUNTA'|translate}}</span>
                                    <h3>80 km/h</h3>
                                    <span>{{'POTENCIA MÁXIMA'|translate}}</span>
                                    <h3>70 %</h3>
                                    <span>{{'PAR MÁXIMO'|translate}}</span>
                                    <h3>80 %</h3>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="container-modos">
                                <div class="container"><br>
                                    <h2>SPORT</h2>
                                    <p>{{'En este modo no hay limite de velocidad maxima (hasta 125km/h) y es el adecuado para rodar en autopistas y autovias. La autonomia del vehiculo se reduce considerablemente.'|translate}}</p>
                                </div>
                                <div class="container" style="text-align: center;">
                                    <span>{{'AUTONOMÍA APROX.*'|translate}}</span>
                                    <h3>75 km</h3>
                                    <span>{{'VELOCIDAD PUNTA'|translate}}</span>
                                    <h3>125 km/h</h3>
                                    <span>{{'POTENCIA MÁXIMA'|translate}}</span>
                                    <h3>100 %</h3>
                                    <span>{{'PAR MÁXIMO'|translate}}</span>
                                    <h3>100 %</h3>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="container-modos">
                                <div class="container"><br>
                                    <h2>ECO</h2>
                                    <p>{{'En este modo, la velocidad maxima esta limitada a 50 km/h y es el adecuado para consumir menos energia de la bateria preferiblemente en utilizacion urbana.'|translate}}</p>
                                </div>
                                <div class="container" style="text-align: center;">
                                    <span>{{'AUTONOMÍA APROX.*'|translate}}</span>
                                    <h3>150 km</h3>
                                    <span>{{'VELOCIDAD PUNTA'|translate}}</span>
                                    <h3>50 km/h</h3>
                                    <span>{{'POTENCIA MÁXIMA'|translate}}</span>
                                    <h3>60 %</h3>
                                    <span>{{'PAR MÁXIMO'|translate}}</span>
                                    <h3>70 %</h3>
                                </div>
                            </div>
                        </div>
                    </div><br>
                    <span>{{'(*) 105 km de autonomía segun ensayo WMTC Fase 3'|translate}}</span>
                </div>
            </div>
        </div>
    </div>
</div>