import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VehiculoService } from '../../../services/vehiculo.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-historico-vehiculo',
  templateUrl: './historico-vehiculo.component.html',
  styleUrls: ['./historico-vehiculo.component.css']
})
export class HistoricoVehiculoComponent implements OnInit {
  public historialForm: FormGroup;
  public loading: boolean = false;
  public loadingPin: boolean = false;
  public vehiculo: any;
  public matricula: any;
  public pinOk: boolean = false;

  constructor(private formBuilder: FormBuilder, private vehiculoService: VehiculoService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.createForm();
    this.route.queryParams.subscribe(params => {
      this.historialForm.patchValue({ matricula: params['vehicle_id']});
    })
  }

  createForm() {
    this.historialForm = this.formBuilder.group({
      matricula: ['', Validators.required],
      codigo: ['']
    });
  }

  consultarVehiculo() {
    this.loading = true;
    this.vehiculo = ""
    if(this.historialForm.invalid) {
      return;
    }
    
    let data = this.historialForm.value;
    this.matricula = this.historialForm.value.matricula;

    this.vehiculoService.buscarVehiculo(data).subscribe({
      next: (r: any) => {
        this.loading = false;
        this.vehiculo = r.message;   
      },
    })
  }

  solicitarPin(){
    this.loadingPin = true;
    let data = {
      "matricula": this.matricula
    }
    this.vehiculoService.solicitarPin(data).subscribe({
      next: (r: any) => {
        this.loadingPin = false;
        this.pinOk = true;
      }
    })
  }


}
