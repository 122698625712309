<div class="modal fade" id="fichatecnica">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="modal-dialog">
                    <h2>{{'FICHA TÉCNICA'|translate}}</h2>
                    <h3>{{'DIMENSIONES Y PESO'|translate}}</h3>
                    <p>
                        {{'Longitud: 2227 mm'|translate}} <br /> {{'Ancho: 786 mm'|translate}} <br /> {{'Altura 1291 mm'|translate}} <br /> {{'Distancia entre ejes: 1617 mm'|translate}} <br /> {{'Distancia al suelo: 860 mm'|translate}} <br /> {{'Altura del asiento: 800 mm'|translate}}<br /> {{'Peso: 202 kg'|translate}} <br /> {{'Carga Max.: 362kg'|translate}} <br /> {{'Frenos Disco: CBS'|translate}}
                    </p>
                    <hr />
                    <h3>{{'BATERÍA'|translate}}</h3>
                    <p>
                        {{'Autonomía: 150 km (Modo ECO), 115 km (Modo NORMAL), 75 km (Modo SPORT)'|translate}} <br /> {{'Capacidad: 76.8 V 75 Ah'|translate}} <br /> {{'Capacidad: 5,76 kwh (2.88x2)'|translate}} <br /> {{'Ciclos carga: 6000'|translate}} <br /> 
                        {{'Tiempo carga completa: 4.20 h'|translate}}
                        <br />
                        {{'Tiempo carga al 80%: 3.20 h'|translate}}
                    </p>
                    <hr />
                    <h3>{{'MOTOR'|translate}}</h3>
                    <p>
                        {{'Potencia neta máx. (EU 134/2014) : 9 kW - 12,1 CV'|translate}} <br>
                        {{'Potencia pico: 12 kW - 16,1 CV'|translate}} <br>
                        {{'Velocidad punta: 125 km/h'|translate}} <br>
                        {{'Autonomía: 150 km (Modo ECO), 115 km (Modo NORMAL), 75 km (Modo SPORT)'|translate}}
                    </p>
                    <hr />
                    <h3>{{'NEUMÁTICOS'|translate}}</h3>
                    <p>
                        {{'Delantero: 120/70-15'|translate}} <br /> {{'Trasero: 140/70-14'|translate}}
                    </p>
                    <hr />
                </div>
            </div>
        </div>
    </div>
</div>