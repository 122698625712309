import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GeoapiService } from 'src/app/services/geoapi.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-homepage2',
  templateUrl: './homepage2.component.html',
  styleUrls: ['./homepage2.component.css']
})
export class Homepage2Component implements OnInit, AfterViewInit {
  public lang: string;
  idioma: string;
  popuphome: boolean = false;


  constructor(private geoApiService: GeoapiService, private route: ActivatedRoute, private translate: TranslateService, private location: Location) { }

  ngOnInit(): void {
    // this.initializeLanguage();

    if (this.location.path() === '') {
      this.popuphome = true;
      console.log(this.popuphome + 'home');
    }
  }

  ngAfterViewInit(): void {
    this.activateLink();
  }

  initializeLanguage() {
    this.lang = sessionStorage.getItem("lang");

    if (!this.lang) {
      this.lang = navigator.language.split("-")[0];
    }

    this.translate.setDefaultLang('es');
    this.translate.use(this.lang);
    this.idioma = this.lang;

    if (!this.lang) {
      this.geoApiService.getIPAddress().then(ipAddress => {
        this.geoApiService.getCountry(ipAddress).subscribe({
          next: (r: any) => {
            this.lang = r.message?.country?.iso_code.toLowerCase();
            this.translate.use(this.lang);
            this.idioma = this.lang;
          }
        });
      });
    }
  }


  activateLink() {
    const enlace = document.getElementById('elBoton');
    if (enlace) {
      enlace.click();
    }
  }

  goDown() {
    const newsSecDesktop = document.querySelector('.news-sec-desktop') as HTMLElement;
    if (newsSecDesktop) {
      newsSecDesktop.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }
}
