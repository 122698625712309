import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactoService } from '../../../services/contacto.service';
import { ConcesionariosService } from '../../../services/concesionarios.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';


@Component({
  selector: 'app-solicitud-pruba-form',
  templateUrl: './solicitud-pruba-form.component.html',
  styleUrls: ['./solicitud-pruba-form.component.css']
})
export class SolicitudPrubaFormComponent implements OnInit {

  public contactoForm: FormGroup;
  public loading: boolean = false;
  public exito: boolean = false;
  public submitted: boolean = false;
  public concesionarios: any [] = [];
  public concesionario: any;
  public evento: any;
  public concesionarioName: any;
  public eventName: any;
  public provincias: any [] = [];
  public isLoading = true;
  horariosDisponibles:any
  formularioCompleto = false
  feria_o_evento:string;
  public hora:boolean = false;


  checked = false;
  indeterminate = false;
  labelPosition:any;
  disabled = false;


  public fechas = ''
// Fechas disponibles para el datepicker
public fechasDisponibles:any

// Función para filtrar fechas
public filtroFechas = (date: Date) => {
  const fechaString = this.formatoFecha(date);

  // Retorna true si la fecha está en la lista de fechas disponibles
  return this.fechasDisponibles.includes(fechaString);
}


  constructor( private formBuilder: FormBuilder,private route: ActivatedRoute, private contactoService: ContactoService, private concesionariosService: ConcesionariosService, private router: Router) {
    let lang = navigator.language.split("-")[0];
    // if (lang != "es") {
    //   this.router.navigate(['proximamente']);
    // }

    this.route.params.subscribe(params => {
      if(params['id']){
        this.concesionarioName = params['id']
        this.getConcesionario();
      }
    });

    this.route.queryParams.subscribe(params =>{
      if(params['event']){
        this.eventName = params['event']
        this.getEvento();
      }
    })
  }

  ngOnInit(): void { 
    this.createForm();
    this.getConcesionario();
    this.getEvento();
    this.route.queryParams.subscribe(params => {
      if (params['event'] === '61fa2d6f7b') {
        const fixedDate = new Date('6/20/2024'); // Fecha fija que deseas establecer
        this.contactoForm.get('date').setValue(fixedDate);
        this.hora = true; // Establecer this.hora como true
        const formattedDate = this.formatoFecha(fixedDate); // Formatear la fecha
        
        this.horariosDisponibles = this.fechas[formattedDate]; // Usar formattedDate como clave
      }
    });
   }

   getAvailabeDays(){
    this.isLoading = true

    this.concesionariosService.getAvailabeDays(this.concesionario, this.eventName).subscribe({
      next: (r: any) => {
        this.fechas = r.message;
        this.fechasDisponibles = Object.keys(this.fechas);
        console.log(this.fechasDisponibles);
        this.createForm();
        console.log(this.fechas);
        this.isLoading = false;

      }
    });
    
  }

  getConcesionario(){
    this.concesionariosService.getPlace(this.concesionarioName).subscribe({
      next: (r: any) => {
          this.concesionario = r.message
          console.log(this.concesionario)
          this.getAvailabeDays();
      }
  })
  }
  getEvento(){
    this.concesionariosService.getEvent(this.eventName).subscribe({
      next: (r: any) => {
        this.evento = r.message;
        console.log(this.evento);
        this.getAvailabeDays();
        if(this.evento){
          this.contactoForm.patchValue({ event:this.evento.name});
        }        
      }
    });
  }
  toggleEvent(): void {
    if (this.evento !== undefined) {
      this.evento = this.evento === '1' ? '0' : '1';
    } else {
      this.evento = '0'; // Si evento no está definido, inicializarlo a '1'
    }
  }

  getClass() {
    return this.concesionario ? 'banner-slider' : '';
  }

  getBackgroundStyle() {
    if (this.evento?.event_name && this.evento?.logo_evento) {
      const imageUrl = this.getFullImageUrl(this.evento.logo_evento);
      return {
        'display': 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'background-image': `url(${imageUrl})`,
        'background-position': '100vw 108vh',
        'background-size': 'cover',
        'width': '100vw',
        'height': '70vh',
      };
    }
    return {};
  }
  

  getFullImageUrl(relativeUrl: string): string {
    return `https://erp.nerva.eco/${relativeUrl}`;
  }

  
  createForm() {
    this.contactoForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      // document_type: ['', Validators.required],//no
      // document_number: ['', Validators.required],//no
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', Validators.required],
      // address:['', Validators.required],//no
      // city:['', Validators.required],//no
      // country:['', Validators.required],//no
      postal_code: ['', Validators.required],
      // driving_license_type: ['', Validators.required],//no
      date_and_hour:[''],
      date: ['',Validators.required],
      hour:['',Validators.required],
      place:[this.concesionarioName, Validators.required],
      allow_calls:[''],
      termino_condiciones_politicas: ['',Validators.required],
      event:['']
    });
    this.route.queryParams.subscribe(params => {
      if (params['event'] === '61fa2d6f7b') {
        const fixedDate = new Date('6/20/2024'); // Fecha fija que deseas establecer
        this.contactoForm.get('date').setValue(fixedDate);
        this.hora = true; // Establecer this.hora como true
        const formattedDate = this.formatoFecha(fixedDate); // Formatear la fecha
        this.horariosDisponibles = this.fechas[formattedDate]; // Usar formattedDate como clave
    
        console.log('Fecha fija:', fixedDate);
        console.log('Fecha fija formateada:', formattedDate);
        console.log('Horarios disponibles:', this.horariosDisponibles);
      }
    });
  }
  
  
  enviarTurno() {
    this.submitted = true;
  // Marca todos los campos como "touched" para activar las validaciones
  this.markFormGroupTouched(this.contactoForm);

  // Verifica si el formulario es inválido
  if (this.contactoForm.invalid) {
    // Si el formulario es inválido, muestra un mensaje de alerta al usuario
    alert('Por favor complete todos los campos obligatorios.');
    return;
  }

    
    this.loading = true;
    const originalDate = this.contactoForm.value.date; // Obtener la fecha original

    // 1. Formatear la fecha en el formato deseado "yyyy-mm-dd"
    const year = originalDate.getFullYear();
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
    const day = originalDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const formattedHour = this.contactoForm.value.hour; // Mantener la hora sin cambios

    // 2. Concatenar la fecha y la hora en el formato deseado
    const date_hour = `${formattedDate} ${formattedHour}:00`; // Formato: "2023-12-01 13:30:00"

    this.contactoForm.patchValue({ date_and_hour: date_hour });
    if(this.evento){
      this.contactoForm.patchValue({ event:this.evento.name});
    } 

    console.log(this.contactoForm)

    this.concesionariosService.createAppointment(this.contactoForm.value).subscribe({
      next: (r: any) => {
        this.formularioCompleto = true
          console.log("todo ok")
      }
  })
    
  }
  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  // Función para formatear una fecha a formato "yyyy-MM-dd"
private formatoFecha(date: Date): string {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}

  // Evento para cuando se selecciona una fecha
  public seleccionarFecha(event: MatDatepickerInputEvent<Date>): void {
     // Obtén la fecha seleccionada
  const fechaSeleccionada = event.value;

  // Formatea la fecha seleccionada a 'yyyy-MM-dd'
  const fechaFormateada = this.formatoFecha(fechaSeleccionada);

  // Busca en el JSON usando la fecha formateada como clave
  this.horariosDisponibles = this.fechas[fechaFormateada];

  // Imprime los horarios disponibles
  console.log(this.horariosDisponibles);
  }
}
