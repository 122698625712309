<div class="modal fade" id="financiacion">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="modal-dialog">
                    <h3>{{'FINANCIACIÓN DE BATERÍAS'|translate}}</h3>
                    <h6>{{'El usuario de Nerva EXE podra adquirir las baterías.'|translate}}</h6><br>
                    <div class="row">
                        <div class="col">
                            <div class="container-modos">
                                <div class="container" style="text-align: center;"><br>
                                    <h2 class="h2financiacion">{{'29,90€/mes'|translate}}</h2>
                                    <p class="ppop">{{'Para conocer más en el detalle la condicion del renting, acceda a la página oficial de la contratación.'|translate}}</p>
                                    <br>
                                    <a class="btn-second" style="color:black; background: white; display: none;" href="#">{{'CONTRATAR EL RENTING'|translate}}</a>
                                </div><br>
                            </div>
                        </div>
                    </div><br>
                </div>
            </div>
        </div>
    </div>
</div>