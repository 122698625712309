<app-header2></app-header2>
<!-- Banner sec -->

<div id="miDiv">

    <section *ngIf="!evento" [ngClass]="getClass()">
        <div class="banner-wrapper">
          <div class="container">
            <div class="col-12">
              <h2 class="text-custom-white title">{{ 'Solicitar prueba de conducción' | translate | uppercase | nl2pbr }}</h2>
            </div>
           
          </div>
        </div>
        <div class="gradient"></div>
      </section>
      
      <section *ngIf="evento" class="banner-slider">
        <div class=" evento-titulo">
            <div>
                <p style="display: flex; font-size:50px;color:white; font-weight: 700;" class="">{{ 'Solicitar prueba de conducción' | translate | uppercase | nl2pbr }}</p>

            </div>
            <div class="centro">
                <img width="200px" style="padding-top: 10px;padding-bottom:10px" *ngIf="evento.imagen_1" [src]="'data:image/png;base64,' + evento.imagen_1" >
                <p style="font-size: large;">{{evento.fecha}}</p>
                <p style="font-size: large;">{{evento.direccion}}</p>
                <p style="font-size: large;">{{evento.ciudad}}</p>

            </div>
        </div>
        <div class="gradient"></div>
      </section>
      
</div>

<section class=" news-sec-desktop bg-light-theme d-md-block">
    <div class="container-fluid  backgroud-w p-relative shadow-top" style="background: #FFF;">
        <div class="container" *ngIf="contactoForm && !formularioCompleto">
            <div class="row" style="display: flex; justify-content: center;">
                <div class="col-lg-10 col-md-10 col-sm-12">
                <form [formGroup]="contactoForm">
                    <div class="col-12 mt-5 mb-5" style="padding-top: 2em;" *ngIf="concesionario">
                        <div class="alert alert-dark" role="alert">
                            <h3 class="text-custom-black mb-1 texto-chico"> {{ 'UBICACIÓN SELECCIONADA'| translate }}</h3>
                            <b class="mb-1">{{'Lugar' | translate}} : {{ concesionario.place_name }}</b> <br>
                            <b *ngIf="this.evento" class="mb-1">{{'Evento' | translate}} : {{ evento.event_name }}</b> <br>
                            <b class="mb-1">{{ 'Dirección' | translate }}: {{ concesionario.address + ',' }}</b>
                            <b class="mb-1"> {{ concesionario.city + ','}}</b>
                                <b class="mb-1">  {{ concesionario.country }}</b><br>
                            <b class="mb-1" *ngIf="concesionario.descripcion">{{ concesionario.descripcion }}</b>
                        </div>                          
                    </div>
                <div class="col-12 mt-5 mb-5">
                    <h3 class="text-custom-black texto-chico ">{{'SELECCIONAR FECHA Y HORARIO'|translate}}</h3>
                    <div class="row">
                        <div  *ngIf="isLoading" >
                            <span class="spinner-border spinner-border-sm"></span>      

                        </div>

                        <div class="col-6" *ngIf="fechas">
                            <mat-form-field class="example-full-width">
                                <input formControlName="date" (dateInput)="seleccionarFecha($event)" matInput [matDatepicker]="picker" [placeholder]="'Choose a date' | translate" [matDatepickerFilter]="filtroFechas">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker touchUi #picker></mat-datepicker>
                              </mat-form-field>
                              <div *ngIf="contactoForm.controls['date'].invalid && (contactoForm.controls['date'].dirty || contactoForm.controls['date'].touched || isSubmitted)" class="alert">
                                <small class="text-danger" *ngIf="contactoForm.controls['date'].errors.required">
                                  {{'Selecciona una fecha.'|translate}}
                                </small>
                            </div> 
                        </div>
                    </div>
                    <div class="row mt-5" *ngIf="horariosDisponibles || hora">
                        <div class="col">
                            <section class="example-section">
                                <h4 class="example-margin">{{'Horarios'|translate}}:</h4>
                                <div class="row">
                                    <mat-radio-group formControlName="hour" [(ngModel)]="labelPosition" *ngFor="let horario of horariosDisponibles">
                                        <div class="col-2">
                                            <mat-radio-button class="example-margin" value="{{horario}}">{{horario}}</mat-radio-button>
                                        </div>
                                    </mat-radio-group>
                                </div>
                              </section>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-5 mb-5">
                    <h3 class="text-custom-black texto-chico">{{'Solicitud para la prueba de conducción'|translate|uppercase|nl2pbr}}</h3>
                    <div *ngIf="exito" class="alert alert-success">
                        <p>{{'Gracias por tu interés en Nerva Exe.  Clica en el botón inferior para reservar tu prueba de conducción en el Concesionario seleccionado.'|translate}}                        </p>
                    </div>
                    <div *ngIf="exito">
                        <button type="button" (click)="agendarCita()" class="btn btn-dark">{{'Reserva la cita para tu prueba de conducción'|translate}}</button>
                    </div>
                    <div *ngIf="!exito" class="mt-5">
                        <p>{{'Rellena el siguiente formulario y contactaremos contigo lo antes posible'|translate }}</p>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'NOMBRE'|translate}}</label>
                                        <input type="text" class="form-control" formControlName="first_name" [placeholder]="'Escribe tu nombre' | translate" required>
                                        <div *ngIf="contactoForm.controls['first_name'].invalid && (contactoForm.controls['first_name'].dirty || contactoForm.controls['first_name'].touched || isSubmitted)" class="alert">
                                            <small class="text-danger" *ngIf="contactoForm.controls['first_name'].errors.required">
                                              {{'Escriba su nombre'|translate}}
                                            </small>
                                        </div> 
                                    </div>

                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'APELLIDOS'|translate}}</label>
                                        <input type="text" class="form-control" formControlName="last_name" [placeholder]="'Escribe tus apellidos' | translate" required>
                                        <div *ngIf="contactoForm.controls['last_name'].invalid && (contactoForm.controls['last_name'].dirty || contactoForm.controls['last_name'].touched || isSubmitted)" class="alert">
                                            <small class="text-danger" *ngIf="contactoForm.controls['last_name'].errors.required">
                                              {{'Escriba su apellido'|translate}}
                                            </small>
                                        </div> 
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <!-- <div class="col-md-6">
                                    
                                    <div class="form-group">
                                        <label>{{'Tipo de documento'|translate}}</label>
                                        <input type="text" class="form-control" formControlName="document_type" [placeholder]="'Escribe tu tipo de documento' | translate" required>
                                        <div *ngIf="contactoForm.controls['document_type'].invalid && (contactoForm.controls['document_type'].dirty || contactoForm.controls['document_type'].touched || isSubmitted)" class="alert">
                                            <small class="text-danger" *ngIf="contactoForm.controls['document_type'].errors.required">
                                              {{'Escriba su tipo de documento'|translate}}
                                            </small>
                                        </div> 
                                    </div>

                                </div> -->
                                <!-- <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'Document Number'|translate}}</label>
                                        <input type="text" class="form-control" formControlName="document_number" [placeholder]="'Escriba su documento' | translate" required>
                                        <div *ngIf="contactoForm.controls['document_number'].invalid && (contactoForm.controls['document_number'].dirty || contactoForm.controls['document_number'].touched || isSubmitted)" class="alert">
                                            <small class="text-danger" *ngIf="contactoForm.controls['document_number'].errors.required">
                                              {{'Escriba su documento'|translate}}
                                            </small>
                                        </div> 
                                    </div>

                                </div> -->
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'Email'|translate}}</label>
                                        <input type="text" class="form-control" formControlName="email" [placeholder]="'Escribe tu email' | translate" required>
                                        <div *ngIf="contactoForm.controls['email'].invalid && (contactoForm.controls['email'].dirty || contactoForm.controls['email'].touched || isSubmitted)" class="alert">
                                            <small class="text-danger" *ngIf="contactoForm.controls['email'].errors.required">
                                              {{'Escriba su email'|translate}}
                                            </small>
                                        </div> 
                                    </div>

                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'Número Telefónico'|translate}}</label>
                                        <input type="text" class="form-control" formControlName="phone_number" [placeholder]="'Escribe tu telefono' | translate" required>
                                        <div *ngIf="contactoForm.controls['phone_number'].invalid && (contactoForm.controls['phone_number'].dirty || contactoForm.controls['phone_number'].touched || isSubmitted)" class="alert">
                                            <small class="text-danger" *ngIf="contactoForm.controls['phone_number'].errors.required">
                                              {{'Escriba su telefono'|translate}}
                                            </small>
                                        </div> 
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'Código postal'|translate}}</label>
                                        <input type="text" class="form-control" formControlName="postal_code" [placeholder]="'Escribe tu código postal' | translate" required>
                                        <div *ngIf="contactoForm.controls['postal_code'].invalid && (contactoForm.controls['postal_code'].dirty || contactoForm.controls['postal_code'].touched || isSubmitted)" class="alert">
                                            <small class="text-danger" *ngIf="contactoForm.controls['postal_code'].errors.required">
                                              {{'Escriba su código postal'|translate}}
                                            </small>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <!-- <div class="form-group">
                                        <label>{{'Domicilio'|translate}}</label>
                                        <input type="text" class="form-control" formControlName="address" [placeholder]="'Escribe tu dirección' | translate" required>
                                        <div *ngIf="contactoForm.controls['address'].invalid && (contactoForm.controls['address'].dirty || contactoForm.controls['address'].touched || isSubmitted)" class="alert">
                                            <small class="text-danger" *ngIf="contactoForm.controls['address'].errors.required">
                                              {{'Escriba su direccion'|translate}}
                                            </small>
                                        </div> 
                                    </div> -->

                                </div>
                            </div>
                            <div class="row">
                                <!-- <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'Pais'|translate}}</label>
                                        <select formControlName="country" >
                                            <option selected="selected"value="España">{{'España'|translate}}</option>
                                            <option value="Italia">{{'Italia'|translate}}</option>
                                            <option value="France">{{'France'|translate}}</option>
                                        </select>
                                    
                                    </div>
                                </div> -->
                                <!-- <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{'Ciudad'|translate}}</label>
                                        <input type="text" class="form-control" formControlName="city" [placeholder]="'Escribe tu ciudad' | translate" required>
                                        <div *ngIf="contactoForm.controls['city'].invalid && (contactoForm.controls['city'].dirty || contactoForm.controls['city'].touched || isSubmitted)" class="alert">
                                            <small class="text-danger" *ngIf="contactoForm.controls['city'].errors.required">
                                              {{'Escriba su ciudad'|translate}}
                                            </small>
                                        </div> 
                                    </div>
                                </div> -->
                            </div>
                            <!-- <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>{{'Tipo de licecia'|translate}}</label>
                                        <select formControlName="driving_license_type" >
                                            <option selected="selected" value="Tipo A1">{{'Tipo A1'|translate}}</option>
                                            <option value="Tipo B1">{{'Tipo B1'|translate}}</option>
                                        </select>
                                      
                                    </div>

                                </div>
                            </div> -->
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-10 col-md-10 col-sm-12">
                                        <section class="example-section">
                                            <mat-checkbox class="example-margin" style="white-space: normal; word-wrap: break-word;" formControlName="termino_condiciones_politicas">
                                               <span style="white-space: normal;">
                                                {{'Confirmo los'|translate}} <a  id="miBoton" class="btn-second2" style="color:blue ;" href="JavaScript:Void(0);" data-toggle="modal" data-target="#prueba-conduccion-terminos-condiciones">{{'Terminos & Condiciones y Políticas de Privacidad.'|translate}}</a>
                                               </span> 
                                            </mat-checkbox>
                                            <div *ngIf="contactoForm.controls['termino_condiciones_politicas'].invalid && (contactoForm.controls['termino_condiciones_politicas'].dirty || contactoForm.controls['termino_condiciones_politicas'].touched || isSubmitted)" class="alert">
                                                <small class="text-danger" *ngIf="contactoForm.controls['termino_condiciones_politicas'].errors.required">
                                                  {{'Este campo es obligatorio.'|translate}}
                                                </small>
                                            </div> 
                                            <br>
                                            <mat-checkbox class="example-margin" formControlName="allow_calls">{{'Acepto recibir llamadas telefónicas.'|translate}}</mat-checkbox>  
                                        </section>
                                    </div>
                                </div>
    
                            </div>
                    </div>
                </div>
            </form>
        </div>

            </div>
            <div class="row mb-5" style="display: flex;justify-content: center;">
                    <button [disabled]="loading" class="btn btn-success" (click)="enviarTurno()">{{'Confirm'|translate}}</button>
            </div>
        </div>
        <div class="container mb-5 mt-5" *ngIf="formularioCompleto">
            <div class="alert alert-success" role="alert">
                <h3 class="text-custom-black ">{{'CITA ENVIADA'|translate}}</h3>
                <p>{{'En cuanto el concesionario confirme su turno le llegara un mail avisando que puede asistir la fecha y horario que selecciono.'|translate}}</p>
                <p class="mb-4">{{'Por favor acuda a la prueba con su casco y guantes y lleve la ropa adecuada para poder realizar la prueba. Pantalón largo y zapatos cerrados serán indispensables. Si no dispone de su propio equipamiento contacte con su concesionario.'|translate}}</p>
                <h6>{{'Ubicacion'|translate}} : {{ concesionario.place_name }}</h6>
                <h6>{{'Dirección'|translate}} : {{ concesionario.address }} {{ concesionario.city }} {{ concesionario.country }}</h6>
                <h6>{{'Horario'|translate}} : {{ contactoForm.value.date_and_hour }}</h6>
                <h6>{{'Cliente'|translate}} : {{ contactoForm.value.first_name }} {{ contactoForm.value.last_name }}</h6>
                <p>{{'Muchas gracias por su colaboración.'|translate}}</p>
            </div>
        </div>
    </div>
</section>
<app-footer2></app-footer2>
<app-prueba-conduccion-terminos-condiciones></app-prueba-conduccion-terminos-condiciones>