<app-header2></app-header2>
<!-- Banner sec -->


<section class="banner-slider">
  <div *ngIf="windowWidth > 767">
    <a  id="elBoton" class="btn-second2" href="JavaScript:Void(0);" data-toggle="modal" data-target="#pruebaconduccionpopuphome"></a>

    <div *ngIf="popup" class="modal " id="pruebaconduccionpopup">
    <div class="centrarpop" style="margin-top: 2em; ">
      <div class="modal-content mt-5" >
        <div class="container " >
         
              </div>
            </div>
  
  
        </div>
      <div *ngIf="lang == 'es' || lang =='ar' && ver" class="modal-dialog modal-dialog-centered mt-5"> 
          <div class="modal-content">
            <div class="">
              <div class="background-image2">
                <div class="">
                  <div id="carouselExampleIndicators" class="carousel slide" style="width:100%" data-ride="carousel" data-interval="5000">
                    <div class="carousel-inner">
                      <!-- Primer conjunto de contenido -->
                      <div class="carousel-item " style="height:auto">
                        <div class="" style="background-color:black; padding:1em; ">
                          <div class="row text-start ">
                            <div class="col-6 mt-3 cols poptext d-flex justify-content-center">
                              <a href="/prensa" style="text-decoration: none;">
                                <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Notas de Prensa'|translate}}</span>
                              </a>
                            </div>
                            <div class="col-6 mt-3 d-flex poptext justify-content-center">
                              <a href="/pruebadeconduccion" style="text-decoration: none;">
                                <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Pruebas de Conducción'|translate}}</span>
                              </a>
                            </div>
                            <!-- <div class="col-4 mt-3 d-flex poptext justify-content-center">
                              <a href="/prensa" style="text-decoration: none;">
                                <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Nerva en los Medios'|translate}}</span>
                              </a>
                            </div> -->
                          </div>
                          <div class="row text-start ml-2">
                            <div class="col-6 mt-5 d-flex justify-content-start">
                              <div class="col-12 text-left">
                                <span style="color:white; font-size:18px; font-weight:10">{{'La prensa habla sobre NERVA EXE'|translate}}</span>
                                <h2 class="mt-3" style="font-weight:100; padding-right:2em">{{'El maxiscooter eléctrico con baterías BYD'|translate}}</h2>
                                <div class="botonpop row mt-5 ml-1" style="width:80%">
                                  <div class="col-12 mt-2 mb-2">
                                    <div class="d-flex justify-content-center">
                                      <a href="/solicitar-prueba" style="text-decoration: none; display: flex; align-items: center;">
                                        <span style="color:black; font-weight:500; font-size: 16px;">
                                          {{'SOLICITAR PRUEBA DE CONDUCCIÓN'|translate}}
                                     
                                        <mat-icon class="" style="color:white; font-size: 18px;height:22px; background-color:black; border-radius:50%; padding-top:1px">
                                          arrow_forward
                                        </mat-icon>
                                      </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 mt-5 d-flex justify-content-center ocultar" style="padding-right:2em;height:20em">
                              <div *ngIf="language == 'en' || language == 'es'" class="embed-responsive embed-responsive-16by9">
                                <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/M90pQBroNHg?si=j56AlaeMUSj7Ln9P" allowfullscreen height="400px" width="100%"></iframe>
                              </div>
                              <div *ngIf="language == 'fr'" class="embed-responsive embed-responsive-16by9">
                                <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/yeTPFqxAtSY" allowfullscreen height="400px" width="100%"></iframe>
                              </div>
                              <div *ngIf="language == 'it'" class="embed-responsive embed-responsive-16by9">
                                <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/0z0SUEOepwY" allowfullscreen height="400px" width="100%"></iframe>
                              </div>
                            </div>
                            <div class="row mt-5">
                              <div class="col-12 d-flex justify-content-left ocultar" style="padding-right: 0px; padding-left: 3em;">
                                <p style="color:white; font-size:16px;">
                                  {{'Síguenos en nuestras redes sociales para más noticias:'|translate}}
                                  <a href="https://www.instagram.com/nervamobility/" target="_blank">
                                    <img class="ml-1" style="height: 2em;" src="/assets/images/icons8-instagram (1).svg" alt="">
                                  </a>
                                  <a href="https://www.youtube.com/@nerva.mobility" target="_blank">
                                    <img style="height: 2.5em; padding-left:10px" src="/assets/images/icons8-youtube.svg" alt="">
                                  </a>
                                  <a href="https://www.tiktok.com/@nervamobility?is_from_webapp=1&sender_device=pc" target="_blank">
                                    <img style="height: 2.5em; padding-left:10px" src="/assets/images/icons8-tik-tok-48.png" alt="">
                                  </a>
                                  <a href="https://www.facebook.com/Nervamobility" target="_blank">
                                    <img style="height: 2.2em; padding-left:10px" src="/assets/images/icons8-facebook (1).svg" alt="">
                                  </a>
                                  <a href="https://es.linkedin.com/company/nerva.eco" target="_blank">
                                    <img style="height: 2.1em; padding-left:10px" src="/assets/images/linkedin-svgrepo-com.svg" alt="">
                                  </a>
                                </p>
                              </div>
                            </div>
                            <div class="col-12 cols d-flex justify-content-center mb-1">
                              <button type="button" class="btn btn-block bordes-btm d-flex align-items-center justify-content-center" style="font-size: 12px; background-color: black; color: white; padding:0px; width:10em" data-dismiss="modal" (click)="cerrarPopup()">
                                <mat-icon color="white">close</mat-icon>
                                {{ 'Cerrar' | translate }}
                              </button>
                            </div>
                          </div>
                          
                            
                        </div>
                      </div>
        
                      <!-- Segundo conjunto de contenido -->
                       <div class="carousel-item active" style="height:auto">
                        <div class="" style="background-color:black; padding:1em;">
                          <div class="row text-start ">
                            <div class="col-6 mt-3 cols poptext d-flex justify-content-center">
                              <a href="/prensa" style="text-decoration: none;">
                                <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Notas de Prensa'|translate}}</span>
                              </a>
                            </div>
                            <div class="col-6 mt-3 d-flex poptext justify-content-center">
                              <a href="/pruebadeconduccion" style="text-decoration: none;">
                                <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Pruebas de Conducción'|translate}}</span>
                              </a>
                            </div>
                            <!-- <div class="col-4 mt-3 d-flex poptext justify-content-center">
                              <a href="/prensa" style="text-decoration: none;">
                                <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Nerva en los Medios'|translate}}</span>
                              </a>
                            </div> -->
                          </div>
                          <div class="row text-start ml-2" >
                            <div class="col-6 mt-5 d-flex justify-content-start" >
                              <div class="col-12 text-left" >
                                <span style="color:white; font-size:16px;font-weight:10">{{'NERVA EXE renovado y más barato!!'|translate}}</span>
                                <h2 class="mt-3" style="font-weight:200; padding-right:2em">{{'NUEVO PRECIO'|translate}} <br> {{'Desde'|translate}} <span style="color:#007bff">{{'2.780 €'|translate}}</span> </h2>
                                <div class="col-12 d-flex justify-content-left " style="padding:0;">
                                  <p class="col-12" style="color:white; font-size:16px; padding:0;" >{{'PVP: 5990 €. Puedes comprar la parte ciclo por 3.880 €, solicitar el MOVES III (1.100 € si no achatarras y 1.300 € si achatarras) alquilar las baterías por 39,90 € al mes durante 5 años.'|translate}}      
                                  </p>
                    
                                </div>
                                                                  <p style="font-size: x-small;"><a href="/quiero-una-nerva">{{'Toda la info aquí.'|translate}}</a></p>

                                <div class="botonpop row mt-3 ml-1" style="width:80%;background-color:#007bff; border-radius:20px">
                                  <div class="col-12 mt-2 mb-2" style="background-color:#007bff; border-radius:20px">
                                    <div class="d-flex justify-content-center mt-2 mb-2" >
                                      <a href="/solicitar-prueba"style="text-decoration: none;">
                                        <span style="color:black; font-weight:500;" class="text-center">
                                          {{'SOLICITAR PRUEBA DE CONDUCCIÓN'|translate}}
                                        </span> 
                                        <mat-icon class="" style="color:white; font-size: 18px;height:22px; background-color:black; border-radius:50%; padding-top:1px">
                                          arrow_forward
                                        </mat-icon>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 mt-5 d-flex justify-content-center ocultar" style="padding-right:2em;">
                             <img src="/assets/images/05.webp" alt="">
                            </div>
                       

                            <div class="row mt-5">
                              <div class="col-12 d-flex justify-content-left ocultar"style="padding-right: 0px;padding-left: 3em;">
                                <p style="color:white; font-size:16px;" >{{'Síguenos en nuestras redes sociales para más noticias:'|translate}}      
                                  <a href="https://www.instagram.com/nervamobility/" target="_blank">
                                    <img class="ml-1" style="height: 2em;" src="/assets/images/icons8-instagram (1).svg" alt="">
                                  </a>
                                     <a href="https://www.youtube.com/@nerva.mobility" target="_blank">
                                    <img style="height: 2.5em; padding-left:10px" src="/assets/images/icons8-youtube.svg" alt="">
                                  </a>  
                                  <a href="https://www.tiktok.com/@nervamobility?is_from_webapp=1&sender_device=pc" target="_blank">
                                    <img style="height: 2.5em; padding-left:10px" src="/assets/images/icons8-tik-tok-48.png" alt="">
                                  </a>
                                  <a href="https://www.facebook.com/Nervamobility" target="_blank">
                                      <img style="height: 2.2em; padding-left:10px" src="/assets/images/icons8-facebook (1).svg" alt="">
                                  </a>
                                  <a href="https://es.linkedin.com/company/nerva.eco" target="_blank">
                                    <img style="height: 2.1em; padding-left:10px" src="/assets/images/linkedin-svgrepo-com.svg" alt="">
                                  </a>
                                </p>
                              </div>
                            </div>
                            <div class="col-12 cols d-flex justify-content-center mb-2">
                              <button type="button" class="btn btn-block bordes-btm d-flex align-items-center justify-content-center" style="font-size: 12px; background-color: black; color: white;padding:0px; width:10em" data-dismiss="modal" (click)="cerrarPopup()">
                                <mat-icon color="white">close</mat-icon>
                                {{ 'Cerrar' | translate }}
                              </button>
                            </div>

          
                          </div>
                        </div>
                      </div> 
                    </div>
        

                     <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


         <div *ngIf="lang !== 'es' && lang !=='ar' && ver" class="modal-dialog modal-dialog-centered mt-5">
          <div class="modal-content">
            <div class="">
              <div class="background-image2">
                <div class="">
                  <div id="carouselExampleIndicators" class="carousel slide" style="width:100%" data-ride="carousel" data-interval="5000">
                    <div class="carousel-inner">

                      <div class="carousel-item active "style="height:auto">
                        <div class="" style="background-color:black; padding:1em;">
                          <div class="row text-start ">
                            <div class="col-6 mt-3 cols poptext d-flex justify-content-center">
                              <a href="/prensa" style="text-decoration: none;">
                                <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Notas de Prensa'|translate}}</span>
                              </a>
                            </div>
                            <div class="col-6 mt-3 d-flex poptext justify-content-center">
                              <a href="/pruebadeconduccion" style="text-decoration: none;">
                                <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Pruebas de Conducción'|translate}}</span>
                              </a>
                            </div>
                            <!-- <div class="col-4 mt-3 d-flex poptext justify-content-center">
                              <a href="/prensa" style="text-decoration: none;">
                                <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Nerva en los Medios'|translate}}</span>
                              </a>
                            </div> -->
                          </div>
                          <div class="row text-start ml-2" >
                            <div class="col-6 mt-5 d-flex justify-content-start" >
                              <div class="col-12 text-left " >
                                <span style="color:white; font-size:18px;font-weight:10">{{'La prensa habla sobre NERVA EXE'|translate}}</span>
                                <h2 class="mt-3" style="font-weight:100; padding-right:2em">{{'El maxiscooter eléctrico con baterías BYD'|translate}}</h2>
                                <div class="botonpop row mt-5 ml-1" style="width:80%">
                                  <div class="col-12 mt-2 mb-2">
                                    <div class="d-flex justify-content-center">
                                      <a href="/solicitar-prueba"style="text-decoration: none;">
                                        <span style="color:black; font-weight:500;" class="text-center">{{'SOLICITAR PRUEBA DE CONDUCCIÓN'|translate}}
                                        </span> 
                                        <mat-icon class="" style="color:white; font-size: 18px;height:22px; background-color:black; border-radius:50%; padding-top:1px">
                                          arrow_forward
                                        </mat-icon>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 mt-5 d-flex justify-content-center ocultar" style="padding-right:2em; height:20em">
                            <div *ngIf="language == 'en' || language == 'es'" class="embed-responsive embed-responsive-16by9">
                              <iframe class="embed-responsive-item container-video"  src="https://www.youtube.com/embed/M90pQBroNHg?si=j56AlaeMUSj7Ln9P" allowfullscreen height="400px" width="100%" ></iframe>
                            </div>
                            <div *ngIf="language == 'fr'" class="embed-responsive embed-responsive-16by9">
                              <iframe class="embed-responsive-item container-video"  src="https://www.youtube.com/embed/yeTPFqxAtSY" allowfullscreen height="400px" width="100%" ></iframe>
                            </div>
                            <div *ngIf="language == 'it'" class="embed-responsive embed-responsive-16by9">
                              <iframe class="embed-responsive-item container-video"  src="https://www.youtube.com/embed/0z0SUEOepwY" allowfullscreen height="400px" width="100%" ></iframe>
                            </div>
                          </div>
                            <div class="row mt-5">
                              <div class="col-12 d-flex justify-content-left ocultar"style="padding-right: 0px;padding-left: 3em;">
                                <p style="color:white; font-size:16px;" >{{'Síguenos en nuestras redes sociales para más noticias:'|translate}}      
                                  <a href="https://www.instagram.com/nervamobility/" target="_blank">
                                    <img class="ml-1" style="height: 2em;" src="/assets/images/icons8-instagram (1).svg" alt="">
                                  </a>
                                     <a href="https://www.youtube.com/@nerva.mobility" target="_blank">
                                    <img style="height: 2.5em; padding-left:10px" src="/assets/images/icons8-youtube.svg" alt="">
                                  </a>  
                                  <a href="https://www.tiktok.com/@nervamobility?is_from_webapp=1&sender_device=pc" target="_blank">
                                    <img style="height: 2.5em; padding-left:10px" src="/assets/images/icons8-tik-tok-48.png" alt="">
                                  </a>
                                  <a href="https://www.facebook.com/Nervamobility" target="_blank">
                                      <img style="height: 2.2em; padding-left:10px" src="/assets/images/icons8-facebook (1).svg" alt="">
                                  </a>
                                  <a href="https://es.linkedin.com/company/nerva.eco" target="_blank">
                                    <img style="height: 2.2em; padding-left:10px" src="/assets/images/linkedin-svgrepo-com.svg" alt="">
                                  </a>
                                </p>
                              </div>
                              <div class="col-12 cols d-flex justify-content-center mt-3">
                                <button type="button" class="btn btn-block bordes-btm d-flex align-items-center justify-content-center" style="font-size: 12px; background-color: black; color: white;padding:0px; width:10em" data-dismiss="modal" (click)="cerrarPopup()">
                                  <mat-icon color="white">close</mat-icon>
                                  {{ 'Cerrar' | translate }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
        

                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
  </div>
</div>

  <div *ngIf="windowWidth <= 767">
    <div *ngIf="popup" class="modal " id="pruebaconduccionpopup" style="margin-top: 2em; width :90%;left:1em">
      <div class="centrarpop" style="margin-top: 2em; width :90%;left:4em">
        <div class="modal-content mt-5" >
          <div class=" " >
           
                </div>
              </div>
    
    
          </div>
         <div *ngIf="lang =='es' || lang =='ar'" class="modal-dialog modal-dialog-centered " style="width: 90%; ">

            <div class="modal-content">
              <div class="">
                <div class="background-image2">
                  <div class="">
                    <div id="carouselExampleIndicators" class="carousel slide" style="width:100%; padding-left:1em" data-ride="carousel" data-interval="5000">
                      <div class="carousel-inner">
                        <!-- Primer conjunto de contenido -->
                        <div class="carousel-item active">
                          <div class="" style="background-color:black; height:80vh; width:75%;padding-top: 0em; margin-left:5em">
                            <div class="row text-start  pl-1 pr-1 ">
                              <div class="col-6 mt-1 cols poptext d-flex justify-content-center">
                                <a href="/prensa" style="text-decoration: none;">
                                  <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Notas de Prensa'|translate}}</span>
                                </a>
                              </div>
                              <div class="col-6 mt-1 d-flex poptext justify-content-center">
                                <a href="/pruebadeconduccion" style="text-decoration: none;">
                                  <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Pruebas de Conducción'|translate}}</span>
                                </a>
                              </div>
                              <!-- <div class="col-4 mt-1 d-flex poptext justify-content-center">
                                <a href="/prensa" style="text-decoration: none;">
                                  <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Nerva en los Medios'|translate}}</span>
                                </a>
                              </div> -->
                            </div>

                              <div class="col-12 mt-2 d-flex justify-content-left" >
                                <div class="col-12 text-left" >
                                  <span style="color:white; font-size:18px;font-weight:10">{{'La prensa habla sobre NERVA EXE'|translate}}</span>
                                  <h2 class="mt-3" style="font-weight:100;">{{'El maxiscooter eléctrico con baterías BYD'|translate}}</h2>
                                  <div class="botonpop row mt-3 ml-1">
                                    <div class="col-12 mt-2 mb-2">
                                      <div class="d-flex justify-content-center">
                                        <a href="/solicitar-prueba"style="text-decoration: none;">
                                          <span style="color:black; font-weight:500;" class="text-center">{{'SOLICITAR PRUEBA DE CONDUCCIÓN'|translate}}
                                          </span> 
                                          <mat-icon class="" style="color:white; font-size: 24px; background-color:black; border-radius:50%;">
                                            arrow_forward
                                          </mat-icon>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 mt-5 d-flex justify-content-center text-center" style="margin-left:1em;">
                                <div *ngIf="language == 'en' || language == 'es'" class="embed-responsive embed-responsive-16by9">
                                    <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/M90pQBroNHg?si=j56AlaeMUSj7Ln9P" allowfullscreen height="400px" width="100%"></iframe>
                                </div>
                                <div *ngIf="language == 'fr'" class="embed-responsive embed-responsive-16by9">
                                    <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/yeTPFqxAtSY" allowfullscreen height="400px" width="100%"></iframe>
                                </div>
                                <div *ngIf="language == 'it'" class="embed-responsive embed-responsive-16by9">
                                    <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/0z0SUEOepwY" allowfullscreen height="400px" width="100%"></iframe>
                                </div>
                            </div>
                            
                                <div class="col-12 cols ml-4 d-flex justify-content-center mt-5">
                                  <button type="button" class="btn btn-block bordes-btm d-flex align-items-center justify-content-center" style="font-size: 12px; background-color: black; color: white;padding:0px; width:10em" data-dismiss="modal" (click)="cerrarPopup()">
                                    <mat-icon color="white">close</mat-icon>
                                    {{ 'Cerrar' | translate }}
                                  </button>
                                </div>

                            </div>
                          </div>
                           <!-- Segundo conjunto de contenido -->
                      <div class="carousel-item ">
                        <div class="" style="background-color:black; height:80vh; width:75%;padding-top: 0em; margin-left:5em">
                          <div class="row text-start  pl-1 pr-1 ">
                              <div class="col-6 mt-1 cols poptext d-flex justify-content-center">
                                <a href="/prensa" style="text-decoration: none;">
                                  <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Notas de Prensa'|translate}}</span>
                                </a>
                              </div>
                              <div class="col-6 mt-1 d-flex poptext justify-content-center">
                                <a href="/pruebadeconduccion" style="text-decoration: none;">
                                  <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Pruebas de Conducción'|translate}}</span>
                                </a>
                              </div>
                               <div class="col-4 mt-1 d-flex poptext justify-content-center">
                                <a href="/prensa" style="text-decoration: none;">
                                  <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Nerva en los Medios'|translate}}</span>
                                </a>
                              </div> 
                            </div>


                                <div class="col-12 mt-2 d-flex justify-content-start" >
                                  <div class="col-12 text-left" >
                                    <span style="color:white; font-size:16px;font-weight:10">{{'NERVA EXE renovado y más barato!!'|translate}}</span>
                                    <h2 class="mt-3" style="font-weight:200; padding-right:2em">{{'NUEVO PRECIO'|translate}} <br> {{'Desde'|translate}} <span style="color:#007bff">{{'2.780 €'|translate}}</span> </h2>
                                    <div class="col-12 d-flex justify-content-left " style="padding:0;">
                                    
                        
                                    </div>
                                    <p style="font-size: x-small;"><a href="/quiero-una-nerva">{{'Toda la info aquí.'|translate}}</a></p>
    
                                    <div class="botonpop row mt-3 ml-1"style="background-color:#007bff; border-radius:20px">
                                      <div class="col-12 mt-2 mb-2" style="background-color:#007bff; border-radius:20px">
                                        <div class="d-flex justify-content-center">
                                          <a href="/solicitar-prueba"style="text-decoration: none;">
                                            <span style="color:black; font-weight:500;" class="text-center">{{'SOLICITAR PRUEBA DE CONDUCCIÓN'|translate}}
                                            </span> 
                                            <mat-icon class="" style="color:white; font-size: 24px; background-color:black; border-radius:50%;">
                                              arrow_forward
                                            </mat-icon>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                 <div class="col-12 mt-3 ml-1 d-flex justify-content-center " >
                                  <img height="200px"  width="100%" src="/assets/images/05.webp" alt="" style="margin-left:2em">
                                </div> 
                                    <div class="col-12 cols ml-4 d-flex justify-content-center mt-3">
                                      <button type="button" class="btn btn-block bordes-btm d-flex align-items-center justify-content-center" style="font-size: 12px; background-color: black; color: white;padding:0px; width:10em" data-dismiss="modal" (click)="cerrarPopup()">
                                        <mat-icon color="white">close</mat-icon>
                                        {{ 'Cerrar' | translate }}
                                      </button>
                                    </div>
                              </div>
                   

                            </div>
                        </div>
          
                       
                      </div>
          
  
                       <a class="carousel-control-prev pr-5" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                      </a>
                      <a class="carousel-control-next pl-5" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           <div  *ngIf="lang !=='es' && lang !=='ar'" class="modal-dialog modal-dialog-centered " style="width: 90%; ">
            <div class="modal-content">
              <div class="">
                <div class="background-image2">
                  <div class="">
                    <div id="carouselExampleIndicators" class="carousel slide" style="width:100%;" data-ride="carousel" data-interval="5000">
                      <div class="carousel-inner">

                        <div class="carousel-item active">
                          <div class="" style="background-color:black; height:80vh; width:75%;padding-top: 0em; margin-left:5em">
                            <div class="row text-start  pl-1 pr-1 ">
                              <div class="col-6 mt-1 cols poptext d-flex justify-content-center">
                                <a href="/prensa" style="text-decoration: none;">
                                  <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Notas de Prensa'|translate}}</span>
                                </a>
                              </div>
                              <div class="col-6 mt-1 d-flex poptext justify-content-center">
                                <a href="/pruebadeconduccion" style="text-decoration: none;">
                                  <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Pruebas de Conducción'|translate}}</span>
                                </a>
                              </div>
                              <!-- <div class="col-4 mt-1 d-flex poptext justify-content-center">
                                <a href="/prensa" style="text-decoration: none;">
                                  <span class="fontWeight" style="color:white; font-size:18px;font-weight:10;">{{'Nerva en los Medios'|translate}}</span>
                                </a>
                              </div> -->
                            </div>

                              <div class="col-12 mt-2 d-flex justify-content-left" >
                                <div class="col-12 text-left ml-2" >
                                  <span style="color:white; font-size:18px;font-weight:10">{{'La prensa habla sobre NERVA EXE'|translate}}</span>
                                  <h2 class="mt-3" style="font-weight:100;">{{'El maxiscooter eléctrico con baterías BYD'|translate}}</h2>
                                  <div class="botonpop row mt-3 ml-1">
                                    <div class="col-12 mt-2 mb-2">
                                      <div class="d-flex justify-content-center">
                                        <a href="/solicitar-prueba"style="text-decoration: none;">
                                          <span style="color:black; font-weight:500;" class="text-center">{{'SOLICITAR PRUEBA DE CONDUCCIÓN'|translate}}
                                          </span> 
                                          <mat-icon class="" style="color:white; font-size: 24px; background-color:black; border-radius:50%;">
                                            arrow_forward
                                          </mat-icon>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                             <div class="col-12 mt-5 d-flex justify-content-center text-center" style="margin-left:1em;">
                                <div *ngIf="language == 'en' || language == 'es'" class="embed-responsive embed-responsive-16by9">
                                    <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/M90pQBroNHg?si=j56AlaeMUSj7Ln9P" allowfullscreen height="400px" width="100%"></iframe>
                                </div>
                                <div *ngIf="language == 'fr'" class="embed-responsive embed-responsive-16by9">
                                    <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/yeTPFqxAtSY" allowfullscreen height="400px" width="100%"></iframe>
                                </div>
                                <div *ngIf="italian && lang !== 'en' && lang !== 'es' && lang !== 'fr'" class="embed-responsive embed-responsive-16by9">
                                    <iframe class="embed-responsive-item container-video" src="https://www.youtube.com/embed/0z0SUEOepwY" allowfullscreen height="400px" width="100%"></iframe>
                                </div>
                            </div>
                            
                                <div class="col-12 cols ml-4 d-flex justify-content-center mt-1">
                                  <button type="button" class="btn btn-block bordes-btm d-flex align-items-center justify-content-center" style="font-size: 12px; background-color: black; color: white;padding:0px; width:10em" data-dismiss="modal" (click)="cerrarPopup()">
                                    <mat-icon color="white">close</mat-icon>
                                    {{ 'Cerrar' | translate }}
                                  </button>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
    </div>
    <div class="">
        <div class="banner-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div>
                            <div class="about-text-wrapper" style="text-align: left;">
                                <a  id="miBoton" class="btn-second2" href="JavaScript:Void(0);" data-toggle="modal" data-target="#pruebaconduccionpopup"></a>
                                <h2 class="text-custom-white title">{{'NERVA EXE'|translate}}</h2>
                                <p class="text-custom-white sub-title">{{'CONSIGUE TU E-SCOOTER ELÉCTRICO NERVA EXE'|translate}}
                                    <br> <span *ngIf="idioma !== 'en'">
                                        {{ 'DESDE.' | translate }}
                                    </span>
                                    
                                </p>
                                <div  *ngIf="language == 'es'">
                                  <a href="https://www.idae.es/ayudas-y-financiacion/para-movilidad-y-vehiculos/programa-moves-iii" target="_blank"> {{'*PVP  - Moves III y sujeto a la adquisición de las baterías mediante renting 5 años.'|translate}}<u>{{'Más info'|translate}}</u></a><br><br>
                                </div>
                                
                                <div class="about-text-wrapper-blocks">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-4 col-lg-4 text-custom-white block_text_slide">
                                            <span class="text_top">{{'AUTONOMÍA MÁX.'|translate}}
                                                <a class="btn-second2" href="JavaScript:Void(0);" data-toggle="modal" data-target="#modosconduccion">ⓘ</a>
                                            </span><br>
                                            <span class="text_down"><span class="text_big">150</span><sup> km</sup></span>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4 text-custom-white block_text_slide">
                                            <span class="text_top">{{'VELOCIDAD PUNTA'|translate}}
                                                <a class="btn-second2" href="JavaScript:Void(0);" data-toggle="modal" data-target="#modosconduccion">ⓘ</a>
                                            </span><br>
                                            <span class="text_down"><span class="text_big">125</span><sup> km/h</sup> </span>

                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4 text-custom-white block_text_slide">
                                            <span class="text_top">{{'GARANTÍA BATERÍA'|translate}}</span><br>
                                            <span class="text_down"><span class="text_big">5 </span><sup> {{'AÑOS'|translate}}</sup></span>
                                        </div>

                                    </div>

                                </div>
                                <div *ngIf="lang == 'es'" class="text-custom-white pt-3">
                                    <p>{{'*Accede a los planes MOVES III de financiación de tu vehículo eléctrico y adquiere las baterías a través de nuestro plan de renting.'|translate}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <!-- <div class="gradient">

    </div> -->
    <div style="position: absolute; bottom: .625rem; width: 100%; ">
        <div class="row pl-4 pr-4">
            <div class="col-md-5">
                <!--<a class="btn-third orange-btn wow fadeInUp m-1 btb-white btn-fixed-1" id="data-open-nav" data-id="popup-encargar">{{'ENCARGAR'|translate}}</a>-->

                <a class="btn-second orange-btn wow fadeInUp m-1" href="/prueba-conduccion">{{'PRUEBA DE CONDUCCIÓN'|translate}}</a>
            </div>
            <div class="text-center col-md-2">
                <a class="btn-second flecha orange-btn wow fadeInUp m-1" (click)="goDown()"  href="javascript:void(0);">↓</a>
            </div>
            <div class="col-md-5 ">
                &nbsp;
            </div>
        </div>
    </div>
    <app-test-drive-button></app-test-drive-button>
</section>

<!-- News Sec Desktop -->

<section class=" news-sec-desktop bg-light-theme d-md-block ">
    <div class="container-fluid  backgroud-b-g p-relative shadow-top ">

        <div class="row ml-1">
            <div class="col-md-6 mt-10-rem">
                <p class="text-custom-white sub-title" [innerHTML]="'EXE, LA MOVILIDAD DEL FUTURO. SOSTENIBLE Y DURADERA.'|translate|nl2pbr">
                </p>
                <div class="text-custom-white pt-3 mb-3-rem">
                    <p>{{'Exe es el scooter eléctrico urbano más sostenible y duradero del mercado porque integra un módulo de baterías que son clave para la economía circular Nerva. Unas baterías que, además de ser las más seguras del mercado, han sido
                        concebidas para ser reconvertidas en módulos de almacenamientos energéticos; una batería con dos vidas.'|translate}}</p>
                </div>
            </div>
        </div>
        <div class="row ml-1">
            <div class="col-md-10">

                <div class="about-text-wrapper-blocks">
                    <div class="row">
                        <div class="col-sm-12 col-md-4 col-lg-4 text-custom-white block_text_slide">
                            <span class="text_top">{{'AUTONOMÍA MÁX.'|translate}} *</span><br>
                            <span class="text_down"><span class="text_super_big">150</span> km</span>
                        </div>
                        <div class="col-sm-12 col-md-4 col-lg-4 text-custom-white block_text_slide">
                            <span class="text_top">{{'VELOCIDAD PUNTA'|translate}} *</span><br>
                            <span class="text_down"><span class="text_super_big">125</span> km/h</span>
                        </div>
                        <div class="col-sm-12 col-md-4 col-lg-4 text-custom-white block_text_slide">
                            <span class="text_top">{{'POTENCIA NETA MÁX.'|translate}}</span><br>
                            <span class="text_down"><span class="text_super_big">9 </span> kW</span>

                        </div>

                    </div>

                </div>

            </div>

        </div>
        <div class="row ml-1 mt-10-rem ">
            <div class="col-md-7   text-custom-white ">

                <p class="text_small">{{'* La autonomía y la velocidad dependerá del Modo de Conducción seleccionado, ver Ficha Técnica para más información.'|translate}}</p>

            </div>
            <div class="col-md">
                <a class="btn-second orange-btn btb-white wow fadeInUp m-1" href="JavaScript:Void(0);" data-toggle="modal" data-target="#modosconduccion">{{'MODOS DE CONDUCCIÓN'|translate}}  →</a>
                <a class="btn-second orange-btn wow fadeInUp m-1 data-open-nav" href="JavaScript:Void(0);" data-toggle="modal" data-target="#fichatecnica">{{'VER FICHA TÉCNICA'|translate}}  →</a>
            </div>
        </div>

        <div class="row ml-1 mt-3-rem ">

            <div class="col-md">

                <div class="main-slider-6 mb-4">
                    <div class="slide-item">
                        <img src="assets/images/nervaExe/3.jpg" class="img-fluid full-width" alt="Slider Image">

                    </div>
                    <div class="slide-item">
                        <img src="assets/images/nervaExe/4.jpg" class="img-fluid full-width" alt="Slider Image">

                    </div>
                    <div class="slide-item">
                        <img src="assets/images/nervaExe/2.jpg" class="img-fluid full-width" alt="Slider Image">

                    </div>
                </div>

            </div>
        </div>

    </div>

</section>

<section class=" news-sec-desktop bg-light-theme d-md-block ">
    <div class="container-fluid  backgroud-w p-relative shadow-top ">

        <div class="row ml-1">
            <div class="col-md-6 mt-10-rem">
                <b><p>{{'RENDIMIENTO'|translate}}</p></b>
                <h2 [innerHTML]="'POTENCIA Y DURABILIDAD ELÉCTRICA.'|translate|nl2pbr"></h2>
            </div>
        </div>
        <div class="row ml-1 mt-3-rem">
            <div class="col-md-4">
                <p>{{'Un motor silencioso con una gran respuesta en la aceleración que permite una reacción perfecta para el tráfico en ciudad. Con una potencia neta máxima de 9 kW y una velocidad máxima de 110 km/h, solo se necesita el carnet de coche B
                    de conducción para tenerla bajo control.'|translate}}</p>
                <br>
                <br>
                <p>{{'CILINDRADA EQUIVALENTE'|translate}}</p>
                <b><p><span class="text_big">125</span> CC</p></b>
                <br>
                <p>{{'DE 0 A 50 KM/H'|translate}}</p>
                <b><p><span class="text_big">4,2 </span> {{'SEGUNDOS'|translate}}</p></b>
                <br>
                <p>{{'DE 0 A 80 KM/H'|translate}}</p>
                <b><p><span class="text_big">10 </span> {{'SEGUNDOS'|translate}}</p></b>
                <br>
                <br>
                <a class="btn-second orange-btn btb-white" href="JavaScript:Void(0);" data-toggle="modal" data-target="#fichatecnica">{{'VER FICHA TÉCNICA'|translate}}  →</a>
            </div>
            <div class="col-md-8">
                <img src="assets/images/nervaExe/5.jpg" />
            </div>
        </div>
        <div class="row ml-1 mt-3-rem">
            <div class="col-md-4">
                <p>{{'Carga la batería de un día para otro en el confort de tu hogar para disfrutar de 105 km de autonomía. En carretera, es muy fácil conectarla en cualquier punto de carga público distribuidos en la ciudad. Además, dispone de un accesorio
                    que permite enchufarla a cualquier enchufe.'|translate}}</p>
                <br>
                <br>
                <p>{{'CARGADOR INTEGRADO DE'|translate}}</p>
                <b><p><span class="text_big">1,8</span> KW</p></b>
                <br>
                <p>{{'CONECTOR A TOMA DOMÉSTICA INCLUIDO'|translate}}</p>
                <b><p class="text_big">{{'SCHUKO A TIPO 2'|translate}}</p></b>
                <br>
                <p>{{'CARGA COMPLETA'|translate}}</p>
                <b><p><span class="text_big">4:20 </span>{{'HORAS'|translate}}</p></b>
                <br>
                <p>{{'CARGA AL 80%'|translate}}</p>
                <b><p><span class="text_big">3:30 </span>{{'HORAS'|translate}}</p></b>
                <br>
                <br>
            </div>
            <div class="col-md-8">
                <img src="assets/images/nervaExe/6.jpg" />
            </div>
        </div>
        <div class="mt-3-rem">
            &nbsp;
        </div>
    </div>

</section>


<section class=" news-sec-desktop bg-light-theme d-md-block ">
    <div class="container-fluid  backgroud-b-g p-relative shadow-top ">


        <div class="row ml-1 ">
            <div class="col-md-4" style="margin: auto;">
                <b><p class="text-custom-white sub-title mt-3">{{'BATERÍAS DURADERAS Y SEGURAS. NO RENUNCIES AL RENDIMIENTO.'|translate}}</p></b>
                <p class="text-custom-white" [innerHTML]="'Baterías LFP desarrolladas por el fabricante de vehículos eléctricos BYD para ofrecer la máxima vida útil y seguridad. Cuando dejan de tener la capacidad necesaria para tu scooter, nosotros sustituimos las baterías por unas nuevas para dejar la moto con el máximo rendimiento de serie otra vez.'|translate|nl2pbr"></p>
                <br>
                <br>
                <a class="btn-second orange-btn btb-white wow fadeInUp m-1" routerLink="/battery">{{'DESCUBRE BYD'|translate}}</a>
                <a *ngIf="idioma !== 'en'" class="btn-second orange-btn btb-gray" data-toggle="modal" data-target="#financiacion" href="javascript:void(0);">{{'FINANCIACIÓN BATERÍAS'|translate}}  →</a>
            </div>
            <div class="col-md-8">
                <img src="assets/images/nervaExe/7.jpg" />
            </div>
        </div>
        <div class="row ml-1">
            <div class="col-md-6 mt-10-rem">
                <b><p class="text-custom-white">{{'DISEÑO'|translate}}</p></b>
                <h2 style="color: white;">{{'UN DISEÑO QUE TE LO PONE FÁCIL.'|translate}}</h2>
            </div>
        </div>
        <div class="row ml-1 mt-3-rem">
            <div class="col-md-4">
                <p class=" text-custom-white">{{'Una pantalla cortavientos integrada y un carenado de líneas aerodinámicas que guían el flujo de aire para ofrecer la máxima estabilidad y control a grandes velocidades. Unas formas que aúnan diseño, seguridad y rendimiento.'|translate}}</p>
            </div>
            <div class="col-md-8">
                <img src="assets/images/nervaExe/8.jpg" />
            </div>
        </div>
        <div class="row ml-1 mt-3-rem">
            <div class="col-md-4">
                <p class=" text-custom-white">{{'Dotada de frenos CBS, la tecnología de frenado más eficaz que regula la presión de frenado en cada rueda para reducir la distancia de frenado. Esto unido a un ancho neumático delantero de 120/70-15 y uno trasero de 140/70-14, otorgan
                    el máximo agarre y confort para la conducción en ciudad.'|translate}}</p>
                <br>
                <br>
                <p class=" text-custom-white">{{'TIPO DE FRENOS'|translate}}</p>
                <p><span class="text_big text-custom-white">CBS</span></p>
                <br>
                <p class=" text-custom-white">{{'TAMAÑO DE LLANTAS'|translate}}</p>
                <p class=" text-custom-white"><span class="text_big ">{{"Delantera 15''"|translate}}</span> </p>
                <p class=" text-custom-white"><span class="text_big ">{{"Trasera 14''"|translate}}</span> </p>

            </div>
            <div class="col-md-8">
                <img src="assets/images/nervaExe/9.jpg" />
            </div>
        </div>

        <div class="row ml-1 mt-10-rem">
            <div class="col-md-12">
                <p class="text-custom-white sub-title "> {{'EQUIPADA CON TODO LO NECESARIO'|translate}}</p>
            </div>
        </div>

        <div class="row ml-1 mt-3-rem">
            <div class="col-md-4">
                <img src="assets/images/nervaExe/10.jpg" />
                <p class="text-custom-white mt-4 "> {{'Potentes faros delanteros y traseros e intermitentes FULL LED.'|translate}}</p>
            </div>
            <div class="col-md-4">
                <img src="assets/images/nervaExe/11.jpg" />
                <p class="text-custom-white mt-4 ">{{'Almacenaje bajo el asiento para un casco integral y un jet.'|translate}}</p>
            </div>
            <div class="col-md-4">
                <img src="assets/images/nervaExe/12.jpg" />
                <p class="text-custom-white  mt-4"> {{'Amplio asiento con estriberas escamoteables para dos pasajeros.'|translate}}</p>
            </div>
        </div>

        <div class="row ml-1 mt-10-rem">
            <div class="col-md-12 text-center">
                <img src="assets/images/nervaExe/13.jpg" style="z-index: 1;" />
            </div>

        </div>

    </div>

</section>


<section class=" news-sec-desktop bg-light-theme d-md-block ">
    <div class="container-fluid  backgroud-w p-relative shadow-top ">

        <div class="row ml-1">
            <div class="col-md-6 mt-10-rem">
                <b><p>{{'EXPERIENCIA'|translate|nl2pbr}}</p></b>

                <h2 class="text-custom-black ">{{'TODO EL CONTROL EN TU MANO.'|translate|nl2pbr}}</h2>

            </div>
        </div>
        <div class="row ml-1 mt-3-rem">
            <div class="col-md-4">
                <p>{{'Además del modo de conducción City orientado a un óptimo rendimiento en ciudad, incorpora un modo Eco para prolongar la autonomía o un modo Sport para sacarle la máxima potencia al motor. También cuenta con un modo marcha atrás para
                    facilitar la maniobrabilidad.'|translate}}</p>
                <br>
                <br>
                <p>{{'MODOS DE CONDUCCIÓN'|translate}}</p>
                <p><b [innerHTML]="'ECO, CITY & SPORT MARCHA ATRÁS'|translate|nl2pbr"></b></p>
                <br>
                <br>
                <a class="btn-second orange-btn btb-white wow fadeInUp m-1" href="JavaScript:Void(0);" data-toggle="modal" data-target="#modosconduccion">{{'MODOS DE CONDUCCIÓN'|translate}}  →</a>
            </div>
            <div class="col-md-8">
                <img src="assets/images/nervaExe/15.jpg" />
            </div>
        </div>
        <div *ngIf="lang == 'es'" class="row ml-1 mt-3-rem">
            <div class="col-md-4">
                <p>{{'Sincronizar la moto con el smartphone es la manera más sencilla para tener acceso a toda la información de la moto. También permite planificar las rutas y lanzarlas a la pantalla LED de 11” de la moto para disfrutar de la experiencia
                    de navegación más cómoda. Además, podrás acceder a el historial completo de rutas e incluso de revisiones de taller.'|translate}}</p>
                <br>
                <br>
                <p>{{'FUNCIONALIDADES DE NERVA APP'|translate}}</p>
                <p><b [innerHTML]="'LOCALIZA TU EXE NAVEGACIÓN NOTIFICACIONES AUTONOMÍA'|translate|nl2pbr"></b></p>
                <br>
                <br>
                <a class="btn-second orange-btn btb-white wow fadeInUp m-1" href="/proximamente">{{'NERVA PARA IOS'|translate}}</a>
                <a class="btn-second orange-btn btb-white wow fadeInUp m-1" href="/proximamente">{{'NERVA PARA ANDROID'|translate}}</a>

            </div>
            <div class="col-md-8">
                <img src="assets/images/nervaExe/16.jpg" />
            </div>
        </div>
        <div class="mt-3-rem">
            &nbsp;
        </div>
    </div>
</section>

<section class=" news-sec-desktop bg-light-theme d-md-block ">
    <div class="container-fluid  backgroud-w p-relative shadow-top ">
        <br><br>
        <b><p>{{'PROCESO DE FABRICACION'|translate|nl2pbr}}</p></b>
        <h2 class="text-custom-black " [innerHTML]="'MAXIMA TECNOLOGIA PARA FABRICAR EL PRIMER E-SCOOTER NERVA'|translate|nl2pbr"></h2>
        <div class="container" style="align-items: center;">
            <div class="fl-bg-video" data-video-mobile="yes" data-width="1920" data-height="1080" data-mp4="/assets/video/modeloEXE/motoFabricacion.mp4" data-mp4-type="video/mp4" data-webm-type="video/webm">
                <video controls loop="" muted="" playsinline="" controls="1" style="height: auto; max-width: 100%;">
                  <source src="/assets/video/modeloEXE/motoFabricacion.mp4" type="video/mp4">
                </video>
            </div>
        </div><br>

    </div>
</section>

<section class="popup-encargar popup" style="transition: all 0.5s ease-out;z-index: 99999; position: fixed; top:0; right:0; width:0%; height:100vh; background:black;">

</section>

<app-footer2></app-footer2>
<app-modos-conduccion></app-modos-conduccion>
<app-ficha-tecnica></app-ficha-tecnica>
<app-renting-baterias></app-renting-baterias>
<app-prueba-conduccion-popup></app-prueba-conduccion-popup>